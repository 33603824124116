<template>
  <div class="tabbar" v-if="showtabbar">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click.stop="changeitem(index, $event)"
    >
      <div
        class="iconfont img"
        :class="[item.src, active == index ? 'active' : '']"
      ></div>
      <div class="text" :class="[active == index ? 'active' : '']">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      active: 0,
      list: [
        {
          title: 'Home',
          name: 'Home',
          path: '/Home',
          src: 'aicean-tabbar1',
          active: false,
        },
        {
          title: 'Videos',
          path: '/V2/videos',
          name: '/V2/videos',
          src: 'aicean-tabbar2',
          active: false,
        },
        {
          title: 'Tasks',
          name: '/V2/task',
          path: '/V2/task',
          src: 'aicean-tabbar3',
          active: false,
        },
        {
          title: 'Mine',
          name: '/V2/My',
          path: '/V2/My',
          src: 'aicean-tabbar4',
          active: false,
        },
      ],
    }
  },
  created() {
    this.updateActiveItem()
  },
  methods: {
    updateActiveItem() {
      this.list.forEach((item, index) => {
        if (this.$route.path == item.path) {
          this.$nextTick(() => {
            this.active = index
          })
        }
      })
    },
    changeitem(index) {
      console.log(index)
      this.active = index
      this.$router.push({ name: this.list[index].name })
    },
  },
  watch: {
    '$route.path': 'updateActiveItem',
  },
  computed: {
    ...mapGetters(['wdWidth', 'token']),
    showtabbar() {
      return (
        this.list
          .concat([
            {
              path: '/customized',
            },
          ])
          .filter((item) => {
            return this.$route.path == item.path
          }).length > 0 && this.token
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.tabbar {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 31.25rem;
  height: 4.58rem;
  background: #1e2135;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.active {
  color: #edbf4f !important;
}
.img {
  font-size: 2rem;
  width: 2.08rem;
  height: 2.08rem;
  margin-bottom: 0.25rem;
  color: #fff;
}
.text {
  font-size: 0.83rem;
  color: #cccccc;
  line-height: 1.08rem;
}
</style>
