import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en.js'
import zhLocale from './zh.js'
import twLocale from './tw.js'

Vue.use(VueI18n)

const messages = {
  en: enLocale,
  cn: zhLocale,
  tw: twLocale,
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages,
  fallbackLocale: 'en',
})

export default i18n
