<template>
  <div class="sidebar">
    <div class="content">
      <div>
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click.stop="changeitem(index, $event)"
        >
          <div class="item_title flex flex-ac">
            <span
              class="iconfont"
              :class="[item.src, active == index ? 'active' : '']"
            ></span>
            <span :class="active == index ? 'active' : ''">{{
              item.title
            }}</span>
            <img
              v-if="item.children.length > 0"
              src="@/assets/creators_icon.png"
              alt=""
              class="creators"
              :class="item.active ? 'creators_active' : ''"
              @click.stop="changecreators(index)"
            />
          </div>
          <div
            v-if="item.children.length > 0"
            class="children"
            :class="item.active ? 'children_active' : ''"
            @click.stop=""
          >
            <div
              class="children_item"
              v-for="(c, i) in item.children"
              :key="i"
              @click.stop="changechildren(c)"
            >
              <span
                class="iconfont"
                :class="[c.src, c.active ? 'children_item_active' : '']"
              ></span>
              <span :class="c.active ? 'children_item_active' : ''">{{
                c.title
              }}</span>
            </div>
          </div>
        </div>
        <div class="you">
          {{ $t('sidebar.You') }}
          <img src="@/assets/creators_icon.png" alt="" class="you_creators" />
        </div>
        <div class="item" style="padding-top: 0">
          <div
            class="item_title flex flex-ac you_item"
            v-for="(item, index) in youlist"
            :key="index"
            @click.stop="changeyouitem(index, $event)"
          >
            <span
              class="iconfont"
              :class="[item.src, youactive == index ? 'active' : '']"
            ></span>
            <span :class="youactive == index ? 'active' : ''">{{
              item.title
            }}</span>
          </div>
        </div>
        <div class="you">{{ $t('sidebar.About_Us') }}</div>
        <div class="about">
          <div class="about_item" @click="toabout">
            {{ $t('sidebar.Regarding_Aicean') }}
          </div>
          <div
            class="about_item"
            :class="[
              $route.name == 'Service' && $route.query.active == 0
                ? 'active'
                : '',
            ]"
            @click="toService(0)"
          >
            {{ $t('sidebar.User_Agreement') }}
          </div>
          <div
            class="about_item"
            :class="[
              $route.name == 'Service' && $route.query.active == 1
                ? 'active'
                : '',
            ]"
            @click="toService(1)"
          >
            {{ $t('sidebar.Privacy_Agreement') }}
          </div>
        </div>
        <div class="you">{{ $t('sidebar.Contact_Us') }}</div>
        <div class="tooltip">
          <el-tooltip content="E-mail">
            <a
              class="channels"
              href="mailto:support@aicean.ai"
              target="_blank"
            ></a>
          </el-tooltip>
          <el-tooltip content="Telegram">
            <a class="channels" href="https://t.me/Aicean" target="_blank"></a>
          </el-tooltip>
          <el-tooltip content="Twitter / X">
            <a
              class="channels"
              href="https://x.com/Aicean_ai"
              target="_blank"
            ></a>
          </el-tooltip>
          <el-tooltip content="Instagram">
            <a
              class="channels"
              href="https://www.instagram.com/aicean_ai"
              target="_blank"
            ></a>
          </el-tooltip>
        </div>
        <div class="Copyright">{{ $t('sidebar.Copyright') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'sidebar',
  data() {
    return {
      active: null,
      list: [
        {
          title: this.$t('sidebar.Home'),
          src: 'aicean-a-bianzu15beifen2',
          active: false,
          name: 'Home',
          path: '/Home',
          children: [],
        },
        {
          title: this.$t('sidebar.Tasks'),
          src: 'aicean-a-bianzu15beifen3',
          name: '/V2/task',
          path: '/V2/task',
          active: false,
          children: [
            {
              title: this.$t('sidebar.Daily_task'),
              name: '/V2/task',
              path: '/V2/task',
              src: 'aicean-a-bianzu17',
            },
            {
              title: this.$t('sidebar.Leaderboard'),
              path: '/V2/leaderboard',
              name: 'Leaderboard',
              src: 'aicean-a-bianzu18',
            },
            // {
            //   title: 'Compagin',
            //   path: '/V2/compagin',
            //   name: 'Compagin',
            //   src: 'aicean-a-bianzu17beifen',
            // },
          ],
        },
        {
          title: this.$t('sidebar.Videos'),
          src: 'aicean-a-bianzu19beifen2',
          active: false,
          path: '/V2/videos',
          name: '/V2/videos',
          children: [],
        },
        {
          title: this.$t('sidebar.My_Portfolio'),
          src: 'aicean-a-bianzu19beifen3',
          name: '/V2/My',
          path: '/V2/My',
          active: false,
          children: [
            {
              title: this.$t('sidebar.Points_Details'),
              path: '/V2/points',
              name: 'points',
              src: 'aicean-a-bianzu17beifen2',
            },
            // {
            //   title: 'Exchange Details',
            //   path: '/V2/exchange',
            //   name: 'exchange',
            //   src: 'aicean-a-bianzu18beifen',
            // },
            {
              title: this.$t('sidebar.Watch_History'),
              path: '/V2/history',
              name: 'history',
              src: 'aicean-a-bianzu17beifen3',
            },
            {
              title: this.$t('sidebar.My_Collection'),
              path: '/V2/collection',
              name: 'collection',
              src: 'aicean-a-bianzu21beifen2',
            },
            {
              title: this.$t('sidebar.My_Videos'),
              path: '/V2/myvideos',
              name: 'myvideos',
              src: 'aicean-myvideo',
            },
            {
              title: this.$t('sidebar.My_Invitation'),
              path: '/V2/invitation',
              name: 'invitation',
              src: 'aicean-a-bianzu20beifen',
            },
            // {
            //   title: 'My Material',
            //   src: 'aicean-a-bianzu21beifen3',
            // },
          ],
        },
      ],
      youactive: null,
      youlist: [
        {
          title: this.$t('sidebar.History'),
          src: 'aicean-a-bianzu17beifen3',
          active: false,
          name: 'history',
          path: '/V2/history',
          children: [],
        },
        {
          title: this.$t('sidebar.Liked_Videos'),
          src: 'aicean-aixing',
          path: '/V2/collection',
          name: 'collection',
          active: false,
          children: [],
        },
        {
          title: this.$t('sidebar.Subscriptions'),
          src: 'aicean-Subscriptions',
          path: '/Attention',
          name: 'Attention',
          active: false,
          children: [],
        },
      ],
    }
  },
  watch: {
    '$route.path': 'updateActiveItem',
  },
  created() {
    this.updateActiveItem()
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    toService(i) {
      this.$router.push({
        name: 'Service',
        query: {
          active: i,
        },
      })
    },
    toabout() {
      this.$router.push({
        name: 'about',
      })
    },
    Comingsoon() {
      this.$message({
        message: 'Coming soon',
        type: 'warning',
        showClose: true,
      })
    },
    updateActiveItem() {
      // 遍历列表，根据当前路由路径设置 active 状态
      if (this.$route.query.you) {
        this.youlist.forEach((item, index) => {
          item.active = this.$route.path == item.path
          if (this.$route.path == item.path) {
            this.youactive = index
          }
        })
      } else {
        this.youlist.forEach((item, index) => {
          item.active = false
          if (this.$route.path == item.path) {
            this.youactive = null
          }
        })
        this.list.forEach((item, index) => {
          item.active = this.$route.path == item.path
          if (this.$route.path == item.path && item.path != '/V2/task') {
            this.active = index
          }
          if (item.children && item.children.length) {
            item.children.forEach((child) => {
              child.active = false
              if (this.$route.path == child.path) {
                this.list[index].active = true
                child.active = true
              }
            })
          }
        })
      }
    },
    changeyouitem(index) {
      console.log(index)
      if (this.youlist[index].children.length > 0) {
        this.youlist[index].active = !this.youlist[index].active
        this.youactive = index
      } else {
        this.youactive = index
      }
      if (this.youlist[index].name == 'Attention') {
        this.$router.push({
          name: this.youlist[index].name,
          query: { id: this.userInfo.id, you: true },
        })
      } else {
        this.$router.push({
          name: this.youlist[index].name,
          query: { you: true },
        })
      }
    },
    changeitem(index) {
      console.log(index)
      if (this.list[index].children.length > 0) {
        this.list[index].active = !this.list[index].active
        if (this.list[index].path != '/V2/task') this.active = index
      } else {
        if (this.list[index].path != '/V2/task') this.active = index
      }
      this.$router.push({ name: this.list[index].name })
    },
    changechildren(item) {
      if (!item.path) {
        this.Comingsoon()
        return
      }
      this.active = item.title
      this.$router.push({ path: item.path })
    },
    changecreators(index) {
      this.list[index].active = !this.list[index].active
    },
  },
}
</script>

<style lang="scss" scoped>
.Copyright {
  margin-top: 1.25rem;
  padding-bottom: 1.67rem;
  width: 11.42rem;
  font-size: 0.83rem;
  color: #888888;
  line-height: 1.17rem;
  text-align: left;
}
.tooltip {
  margin-top: 1.25rem;
  display: flex;
  padding-bottom: 1.67rem;
  border-bottom: 0.08rem solid #313342;
}
.channels {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: contain;
  margin-right: 1.25rem;
  cursor: pointer;
}

.channels:nth-of-type(1) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/emailIcon.png);
}

.channels:nth-of-type(2) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/tg.png);
}

.channels:nth-of-type(3) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/twitter.png);
}
.channels:nth-of-type(4) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/ins.png);
}

.oprate_right > .channels:nth-of-type(1) {
  margin-left: 0 !important;
}
.about {
  margin-top: 1.25rem;
  border-bottom: 0.08rem solid #313342;
  cursor: pointer;
  &_item {
    &:hover {
      color: #edbf4f;
    }
    font-family: SVN-GilroyMedium;
    font-size: 1.17rem;
    color: #999999;
    line-height: 1.5rem;
    text-align: left;
    margin-bottom: 1.67rem;
  }
}
.you {
  font-family: SVN-GilroyMedium;
  font-size: 1.33rem;
  line-height: 1.67rem;
  color: #fff;
  text-align: left;
  margin-top: 1.25rem;
  &_creators {
    margin-left: 0.83rem;
    width: 0.5rem;
    height: 0.83rem;
    rotate: 180deg;
  }
  &_item {
    margin-top: 2.08rem;
  }
}
.item {
  padding: 1.67rem 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border-bottom: 0.08rem solid #313342;
  cursor: pointer;
  user-select: none;
  &_title {
    &:hover {
      span {
        color: #edbf4f;
      }
    }
    span:nth-child(1) {
      font-size: 2rem;
      margin-right: 1rem;
    }
    span {
      font-size: 1.33rem;
      color: #ffffff;
      text-align: right;
    }
  }

  .children {
    width: 100%;
    height: 0;
    overflow: hidden;
    &_active {
      margin-top: 1rem;
      height: 100%;
    }
    &_item {
      display: flex;
      align-items: center;
      padding: 0.7rem 0;
      &:hover {
        span {
          color: #edbf4f;
        }
      }

      span:nth-child(1) {
        font-size: 1.5rem;
        margin: 0 1rem;
      }
      span {
        font-size: 1.17rem;
        color: #cccccc;
      }
      &_active {
        color: #edbf4f !important;
      }
    }
  }
  &_icon {
    width: 1.83rem;
    height: 1.75rem;
    margin-right: 1rem;
  }
}
.item:last-child {
  border-bottom: none;
}
.sidebar {
  position: relative;
  width: 19.58rem;
  padding: 0 1.25rem 0 0;
}
::-webkit-scrollbar {
  display: none;
}
.content {
  position: fixed;
  width: 19.58rem;
  height: calc(100vh - 5rem);
  overflow-y: auto;
  background: #1f2135;
  // border-radius: 1.33rem;
  border: 0.08rem solid #303030;
  box-sizing: border-box;
  padding: 0 1.67rem;
  display: flex;
  flex-direction: column;
}
.creators {
  margin-left: auto;
  width: 0.67rem;
  rotate: -90deg;
  &_active {
    rotate: 90deg;
  }
}

.active {
  color: #edbf4f !important;
}
</style>
