export default {
  guid: {
    New_user: 'New user guidance',
    Welcome_to: `Welcome to the AIcean official website. Click on the task center to
    view the tasks you have completed in real-time`,
    New_users: `New users will receive 200 TAICE when watching the video for the first
    time, watch now`,
    Congratulations: `Congratulations, you have completed your first task and received 200
    TAICE. Please continue to challenge more tasks`,
    Next: `Next`,
    Sure: `Sure`,
  },
  my: {
    Edit: 'Edit',
    My_TAICE: 'My TAICE',
    Earned_Rewards: 'My Rewards',
    Invite_Friends: 'Invite Friends',
    Invitation: 'Invitation',
    Referral: 'Referral',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    WhatsApp: 'WhatsApp',
    Email: 'Email',
    Copy: 'Copy',
    Exclusive_Benefits: 'Exclusive Benefits',
    The_activities: 'The activities I am participating in',
    Ranking_Activity: 'Leaderboard Ranking',
    Your_ranking: 'Ranking on the current contest',
    This_month: `Points earned for current contest`,
    Expected: 'Expected to obtain',
    Reason: 'Contest Minimum Requirements',
    Invite: 'Invite',
    Watch: 'Watch',
    Upload: 'Upload',
    Only: 'Only one place away from the previous one',
    Fraction: 'Fraction',
    Potential: 'Potential ranking',
    If_the: 'If the requirements are met, you will be ranked',
    Rankings: 'Rankings',
    My_Videos: 'My Videos',
    View_All: 'View All',
    No_data: 'No data available at the moment',
    Watch_History: 'Watch History',
    My_Collection: 'My Collection',
    My_Invitation: 'My Invitation',
    Time: 'Time',
    Address: 'Address',
    State: 'State',
    Reward: 'Reward',
    Points_Details: 'TAICE (Points) Details',
    Details: 'Details',
    Obtain: 'Total Points',
    History: 'History',
    A_total: 'A total of',
    items: 'items',
    Today: 'Today',
    Yesterday: 'Yesterday',
    Total_Invited: 'Total Invited Friends',
    Get_Rewards: 'Get Rewards',
    Invitation_record: 'Invitation record',
    User: 'User',
    Option: 'Option',
  },
  videos: {
    Latest_Content: 'Latest Content in Focus',
    View_More: 'View More',
    Currently: 'Popular Videos',
    Recommended: 'Recommended Channel',
    Collected: 'Collected',
    Collect: 'Collect',
    Creator: 'View Creator',
    Share: 'Share',
    Views: 'Views',
  },
  Success: {
    message: 'Thank you for signing up!',
    description: `You’re now on your way to turning your time into earnings. Start watching videos, discover exciting opportunities, and watch your rewards grow. Let’s make every minute count!`,
    action: 'Start Earning Now!',
  },
  Started: {
    tagline: 'Earn by Sharing Your Favorite Videos Online!',
    accountCreationPrompt:
      'Ready to watch? Enter your email to create an account',
    emailLabel: 'Email address',
    registerButton: 'Register Now',
    earningStepsHeader: 'Start your earnings in 4 steps!',
    noSpendingRequired: 'No spending required ever!',
    step1: 'Step 1. ',
    signUp: 'Sign Up',
    step2: 'Step 2. ',
    watchVideos: 'Watch Videos',
    step3: 'Step 3.',
    shareVideos: 'Share Videos',
    step4: 'Step 4. ',
    inviteFriends: 'Invite Friends',
    rankingHeader: 'Top 4- Top 103:',
    winnersShare: 'Winners will share',
    rankingDisplay: 'base on leaderboard ranking display',
    perUser: 'per user.',
    businessModel:
      'Revolutionary business model that shares advertising rewards with users!',
    earningPointsQuestion: 'How do I earn points?',
    earningPointsAnswer:
      'After watching videos, the system will automatically add points to your account.',
    redeemPointsQuestion: 'What can I redeem with points?',
    redeemPointsAnswer:
      'Currently, points can be redeemed for TSPK tokens, with more gift options available in the future.',
    registrationFeeQuestion: 'Is there a fee to register?',
    registrationFeeAnswer:
      'Registration and video watching are free of charge, with no fees required.',
    videoTimeLimitQuestion: 'Is there a time limit for watching videos?',
    videoTimeLimitAnswer:
      'There is no time limit; you can watch videos anytime on your computer, phone, or iPad.',
    checkPointsQuestion: 'How do I check my points?',
    checkPointsAnswer:
      'You can view your current points and tokens on the personal dashboard.',
    issueSupportQuestion: 'What should I do if I encounter an issue?',
    issueSupportAnswer:
      'You can contact us via our TG community or on X, and we will assist you as soon as possible.',
    emailRequired: 'Please fill in the email',
    validEmail: 'Please enter the correct email address',
  },
  Youtube: {
    selectAll: 'Select All',
    channelIdPlaceholder: 'Insert YouTube channel ID',
    searchButton: 'Search',
    moreOptions: 'More',
    category: 'Category',
    publishImmediately: 'Publish (Published immediately after submission)',
    cancelButton: 'Cancel',
    submitButton: 'Submit',
    message: 'Sync Success!',
    error: 'Please select at least one video',
    categoryError: 'Please select a category',
    publishError: 'Please select Publish',
  },
  ProfileEdit: {
    avatarLabel: 'Avatar',
    saveButton: 'Save',
    nicknameLabel: 'Nickname:',
    nicknamePlaceholder: 'Fill the Nickname',
    walletLabel: 'Wallet',
    connectWalletButton: 'Connect Wallet',
    walletAddressLabel: 'Your Wallet Address',
    googleEmailBindingLabel: 'Google Email Binding',
    bindingButton: 'Binding',
    googleEmailLabel: 'Your Google email',
    emailLabel: 'Email',
    emailPlaceholder: 'Fill in the Email',
    sendCodeButton: 'send code',
    verificationCodeLabel: 'Verification Code',
    passwordLabel: 'Password',
    socialLinksLabel: 'Social Links',
    message: 'get userInfo fail',
    savesuccess: 'save success',
    status: 'send code success',
    codeStatus: 'code is not null',
    walletBindingPrompt: 'please bind wallet',
    passwordError: 'password cannot be null',
    walletConnectionPrompt: 'Please Connect Wallet',
    requestError: 'request fail',
    emailChangeStatus: 'change email success',
    walletAlreadyBound: 'user has bind wallet',
    avatar: 'avatar not null',
    nickname: 'nickname not null',
    nicknamelonger: 'nickname to longer',
    twitter: 'twitter not null',
    facebook: 'facebook not null',
    youtube: 'youtube not null',
    instagram: 'instagram not null',
    emailValidator: 'email not null',
  },
  Analyze: {
    viewType: 'View',
    interactionType: 'Like',
    commentType: 'Comment',
    followerCount: 'Followers',
    watchTimeHours: 'Watch time (hours)',
    totalWatchTime: 'Total watch time since the video was published.',
    avgViewDuration: 'Average view duration',
    avgWatchTime: 'Average amount of time viewers spent watching this video.',
  },
  Collect: {
    collections: 'My Collections',
  },
  selfProfileCreation: {
    profileName: 'My Videos',
    action: 'Create',
    platform: 'YouTube',
    filter: 'All',
    sortOrder: 'Latest',
    sortByViews: 'Most Views',
    sortByLikes: 'Most Likes',
    collections: 'My Collections',
  },
  component_user: {
    totalPoints: 'Total Point：',
    totalViewingTime: 'Total viewing time：',
    videos: 'Videos',
    followers: 'Followers',
    following: 'Following',
  },
  selfprofile_my: {
    History: 'History',
  },
  ExchangeHistory: {
    word: 'illustrate',
    points: 'Contribution Point',
    timestamp: 'Time',
    details: 'More',
  },
  videoDetail: {
    actionWatch: 'Watch',
    actionEarn: 'Earn',
    actionView: 'View',
    followersCount: 'Follows',
    actionShare: 'Share',
    noTaskMessage: 'No task at the moment',
    actionClaim: 'Claim',
    commentsCount: 'Comments',
    loginAction: 'log in',
    actionRelease: 'Release',
    replyAction: 'Reply',
    totalReplies: 'Replies in Total',
    loadMoreAction: 'Load More',
    noMoreCommentsMessage: 'No more comments',
    incentiveMessage: 'Incentive',
    recommendedLabel: 'Recommended',
    watchTodayAction: 'Watch Today',
    Follow: 'Follow',
    Unfollow: 'Unfollow',
    Copy: 'Copy',
    Like: 'Like',
    Collect: 'Collect',
    AddComments: 'Add Comments',
    Search: 'Search',
  },
  activityinfo: {
    signupMessage: 'Sign Up and Start Earning Now!',
    eventEndTime: 'The event ends at',
    rankingInfo: 'Top 4- Top 103: Based on the leaderboard display.',
    participationSteps: 'Participation Steps',
    activityRules: 'Activity Rules',
    rewardDistribution: 'Reward Distribution',
  },
  activitycenter: {
    title: 'Activity Center',
    description:
      'Complete tasks, receive rewards, and more perks await your collection',
    action: 'Activity',
    accumulatedRewards: 'Accumulated Rewards：',
    viewActivities: 'View Activities',
    ongoingActivities: 'Ongoing Activities',
    completedActivities: 'Completed Activities',
  },
  Referral: {
    Refer: 'Refer Friends Earn Points',
    description: `Invite friends to register using your exclusive link. Once they
    watch one video and share it for the first time on Aicean, your
    invitation will be confirmed. Hurry and invite your friends to
    experience Aicean, watch videos, and earn points!`,
    referralLink: 'Referral Link：',
    earningMethod: 'How to Easily Earn Points',
    step1: '01. Get the Link',
    step1Details: `Register and log in to Aicean, then share your exclusive invitation
    link with friends who haven't registered yet.`,
    step2: '02. Refer Friends',
    step2Details: `Invite your friends to register. They need to watch one video and
    share it once.`,
    step3: '03. Earn Points',
    step3Details: `Once successful, you will earn 500 points.`,
    rewardDetails: 'Reward Details',
    totalFriendsInvited: 'Total Number of Friends Invited',
    rewardPoints: 'Reward Points',
    referralHistory: 'Referrals History',
    date: 'Date',
    address: 'Address',
    status: 'Status',
    reward: 'Reward',
    noMoreData: 'No more data available',
  },
  Leaderboard: {
    Weekly_RACE: 'Weekly RACE',
    Leaderboard_Contest: 'Leaderboard Contest',
    Race_Rests: 'Campaign ending in',
    DAY: 'DAY',
    HOUR: 'HOUR',
    MIN: 'MIN',
    SEC: 'SEC',
    Has_ended: 'Last Contest (Ended)',
    Daily_Point: 'TAICE Earned in this contest',
    Reward: 'Reward',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    tips1: 'How to Participate:',
    tips2: `1. Sign Up / Log In 2. Bind Your wallet address: Link your wallet address to ensure your earnings are tracked.`,
    tips3: 'Leaderboard Rules:', 
    tips4: 'Top 1 - Top 3 requirements:',
    tips5: 'Invite at least 50 friends',
    tips6: 'Watch at least 100 videos',
    tips7: `Upload 15 AI-related videos`,
    tips8: 'Reward Distribution：',
    tips9: `Rewards Distribution: Rewards will be distributed within a week after verification.
User must ensure a valid wallet address bound to the UID in order to receive rewards.
The final ranking will be finalized at the end of the event, and any point changes occurring afterward will not be considered.
`,
    Learn_more: 'Learn more',
    title: 'Leaderboard',
    description:
      'The leaderboard solely ranks points and is not indicative of prize distribution. To qualify for rewards, participants must fulfill the designated tasks within the campaign. Winners must redeem their prizes based on the announcements made on Twitter. For comprehensive rules, please visit:',
    campaignEndDate: 'Campaign ending in',
    status: 'Completed',
    reward: 'Reward Amount',
    ranking: 'Rank',
    participantName: 'Username',
    contributionScore: 'TAICE Earned in this contest',
    referredBy: 'Referral user',
    uploadvideo: 'Upload Video',
    videoWatched: 'Watch video',
  },
  Task: {
    Signed_In: 'Consecutive Daily Sign In',
    Sign_In: 'Sign In Now',
    SignedIn: 'Signed In',
    tips1: 'AICEAN Task Rules',
    tips2: '1. Task Setup',
    tips3: '5 groups of tasks daily, each with 3 subtasks.',
    tips4: '2. Rewards',
    tips5: 'Completion Reward: Points for completing each group.',
    tips6: 'Bonus: Extra 100 points for completing all 3 subtasks in a group.',
    tips7: '3. Limit',
    tips8: 'Each UID can complete up to 5 groups daily.',
    tips9: '4. Reset',
    tips10: `Tasks will renew everyday at UTC +8 00:00.
TAICE (Points) earned from daily tasks will not be deducted.
`,
    Remain: 'Countdown:',
    Completed: 'Completed',
    To_complete: 'To complete',
    welcomeMessage: 'Welcome to the',
    taskCenter: 'Task Center',
    dailyTaskPrompt: 'Complete daily tasks to earn more points',
    totalPointLabel: 'Total Point',
    totalViewingTimeLabel: 'Total Viewing Time',
    todayPointLabel: 'Today Point',
    todayViewingTimeLabel: 'Today Viewing Time',
    myTaskLabel: 'My task',
    detailsButton: 'Details',
    toFinishLabel: 'To finish',
    dailyTaskCategory: 'Daily Task',
    newUserTaskCategory: 'New User Task',
    hotTaskCategory: 'Hot Task',
    challengeTaskCategory: 'Challenge Task',
  },
  login: {
    placeholder: 'Please select sign-in method',
    emailOption: 'Login/Register with Email',
    loginButton: 'Login',
    signupWithEmail: 'Sign up With Email',
    forgetPassword: 'Forget Password?',
    connectWallet: 'Connect Wallet',
    agreeTo: 'I agree to the',
    termsAndConditions: 'Terms & Conditions',
    signupButton: 'Sign Up',
    welcomeMessage: 'Welcome to AICEAN',
    walletAgreement:
      'By connecting your wallet and using AICEAN, you agree to our Terms and Conditions',
    cancelButton: 'Cancel',
    acceptButton: 'Accept',
    resetButton: 'Reset',
    resetpassword: 'Reset password',
    LoginEmail: 'Login with Email',
    LoginSuccess: 'Login Success',
    emailMessage: 'Please enter the correct email address',
    policyAgreement: 'Please Agree Policy',
    registrationSuccess: 'Register Success',
    passwordResetSuccess: 'Password reset successful',
  },
  header: {
    Beta: 'Beta',
    Login: 'Login',
    Home: 'Home',
    Discover: 'Discover',
    Task: 'Task',
    Leaderboard: 'Leaderboard',
    Referral: 'Referral',
    Campaign: 'Campaign',
    Profile: 'Profile',
    Videos: 'My Videos',
    Collections: 'My Collections',
    Performance: 'Performance',
    Setting: 'Setting',
    Logout: 'Logout',
    Points: 'Points',
    Record: 'Record',
    Today: 'Today',
    Bind_account: 'Bind account',
    Invite: 'Invite friends',
    Copy: 'Copy Link',
    Log_Out: 'Log Out',
    Log_In: 'Log In',
    Sign_up: 'Sign up',
    Welcome: 'Welcome',
    Video: 'Video',
    Fans: 'Fans',
    Follow: 'Follow',
  },
  Home: {
    WATCH: 'WATCH',
    REWARDS: 'REWARDS',
    SHARE: 'SHARE',
    Make: 'Make',
    Money: 'Money',
    by: 'by',
    watching: 'watching and sharing videos',
    Complete: 'Complete tasks and get rewards',
    WATCH_AND: 'WATCH AND EARN',
    Sign: 'Sign up and start earning now',
    Leaderboard: 'Leaderboard',
    Countdown: 'Countdown',
    The_weekly: `If you have completed the minimum contest requirements, you will be ranked in the leaderboard by the total (TAICE)points you earned during the contest period.`,
    View_More: 'View More',
    RANKING: 'RANKING',
    NEW_EARNINGS: 'NEW EARNINGS',
    Recommended_Videos: 'Recommended Videos',
    TOP_PICKS: 'TOP PICKS',
    View_AII: 'View AII',
    Bonus_Status: 'Daily Sign In Bonus',
    Day: 'Day',
    Partner: 'Partner',
    You_need: 'You need to watch a video to complete the check-in process',
    OK: 'OK',
    Registration: 'Registration volume',
    video_num: 'Number of videos',
    total_watch_video_duration: 'Viewing duration',
    Partner: 'Partner',
    Recommend: 'Recommend',
    More: 'More',
  },
  footer: {
    Policy: 'Privacy Policy',
    Service: 'Terms of Service',
    Copyright: 'Copyright 2024 AICEAN. All Right Reserved.',
  },
  sidebar: {
    Home: 'Home',
    Tasks: 'Tasks',
    Daily_task: 'Daily task',
    Leaderboard: 'Leaderboard',
    Videos: 'Videos',
    My_Portfolio: 'My Portfolio',
    Points_Details: 'TAICE (Points) Details',
    Watch_History: 'Watch History',
    My_Collection: 'My Collections',
    My_Videos: 'My Videos',
    My_Invitation: 'My Invitation',
    History: 'History',
    Liked_Videos: 'Liked Videos',
    Subscriptions: 'Subscriptions',
    Copyright: 'Copyright 2024 AICEAN.AII Right Resserved.',
    You: 'You',
    About_Us: 'About Us',
    Regarding_Aicean: 'About AICEAN',
    User_Agreement: 'Privacy Policy',
    Privacy_Agreement: 'Terms of Service',
    Contact_Us: 'Contact Us',
  },
  common: {
    Policy: 'Privacy Policy',
    test: '12345adsas',
    back: 'Back',
    lang: '中文',
    nomore: 'No More',
    copy: 'copy success!',
    Follow: 'Follow Success',
    Unfollow: 'Unfollow Success',
    Like: 'Like Success',
    CancelLike: 'Cancel Like Success',
    change: 'change Success!',
    say: 'please say something!',
    comment: 'comment success!',
    operation: 'operation success!',
    incentiveclose: 'this incentive has been close',
    claim: 'claim success!',
    Cancel: 'Cancel success!',
    Collect: 'Collect success!',
    deletecomment: 'confirm delete this comment ?',
    deletecommentsuccess: 'delete comment success!',
    share: 'share success!',
    Campaign: 'Campaign Upcoming',
    delete: 'delete success !',
    Days: 'Days',
    Day: 'Day',
  },
}
