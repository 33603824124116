<template>
  <footer class="" :style="{ width: `${wdWidth}px` }">
    <div class="box1">
      <img src="@/assets/logLogo.png" alt="" class="box1_logo" />
      <div>
        <div class="title">About Us</div>
        <div class="item" @click="toabout">Regarding Aicean</div>
        <div class="item">Privacy Policy</div>
        <div class="item">Terms of Service</div>
      </div>
      <div>
        <div class="title">Help</div>
        <div class="item" @click="toService(1)">Suggestion and Feedback</div>
        <div class="item">Common Problem</div>
      </div>
      <div>
        <div class="title">Contact Us</div>
        <div class="tooltip" style="display: inline-block">
          <el-tooltip content="E-mail">
            <a
              class="channels"
              href="mailto:support@aicean.ai"
              target="_blank"
            ></a>
          </el-tooltip>
          <el-tooltip content="Telegram">
            <a class="channels" href="https://t.me/Aicean" target="_blank"></a>
          </el-tooltip>
          <el-tooltip content="Twitter / X">
            <a
              class="channels"
              href="https://x.com/Aicean_ai"
              target="_blank"
            ></a>
          </el-tooltip>
          <el-tooltip content="Instagram">
            <a
              class="channels"
              href="https://www.instagram.com/aicean_ai"
              target="_blank"
            ></a>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="box2">
      <a href="https://aicean.ai/" class="box2_text"
        >Copyright 2024 AICEAN.AII Right Resserved.</a
      >
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      showFlag: false,
    }
  },
  methods: {
    toabout() {
      this.$router.push({
        name: 'about',
      })
    },
    changeLang(e) {
      console.dir(e.target.dataset.lg)
    },
    toService(i) {
      this.$router.push({
        name: 'Service',
        query: {
          active: i,
        },
      })
    },
  },
  computed: {
    ...mapGetters(['wdWidth', 'isMoblie']),
    PopStatus() {
      return {
        height: this.showFlag ? '7.8rem' : 0,
        opacity: this.showFlag ? 1 : 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-tooltip__popper {
  font-family: SVN-GilroyMedium !important;
}
footer {
  width: 100%;
  background: #1f2135;
  border-top: 0.13rem solid rgba(255, 255, 255, 0.1);
}
.box1 {
  display: flex;
  height: 17.5rem;
  box-sizing: border-box;
  padding: 3.33rem 16rem 0;
  // align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid #303030;
  &_logo {
    margin: 2rem 0 auto;
    width: 16.67rem;
    height: 4.17rem;
  }
}
.title {
  text-align: left;
  font-weight: 600;
  font-size: 1.5rem;
  color: #ffffff;
}
.item {
  cursor: pointer;
  text-align: left;
  margin-top: 1.25rem;
  line-height: 1.67rem;
  font-weight: 400;
  font-size: 1.17rem;
  color: #aaaaaa;
}
.item:hover {
  color: #ffffff;
}
.tooltip {
  margin-top: 1.25rem;
  display: flex;
}
.channels {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
  margin-right: 1.67rem;
  cursor: pointer;
}

.channels:nth-of-type(1) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/emailIcon.png);
}

.channels:nth-of-type(2) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/tg.png);
}

.channels:nth-of-type(3) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/twitter.png);
}
.channels:nth-of-type(4) {
  display: inline-block;
  background: no-repeat center/100% url(@/assets/ins.png);
}

.oprate_right > .channels:nth-of-type(1) {
  margin-left: 0 !important;
}
.box2 {
  height: 4.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &_text {
    text-align: center;
    font-size: 1.17rem;
    color: #888888;
  }
}
@media screen and (max-width: 900px) {
  .box1 {
    padding: 1rem;
    position: relative;
    &_logo {
      margin: auto;
      bottom: 1rem;
      left: 0;
      right: 0;
      position: absolute;
      width: 9.37rem;
      height: auto;
    }
  }
  .title {
    white-space: nowrap;
    font-size: 1rem;
  }
  .item {
    white-space: normal;
    font-size: 0.8rem;
  }
  .box2 {
    &_text {
      font-size: 1rem;
    }
  }
}
</style>
