<template>
  <header
    class="header flex flex-dir flex-ac flex-jsb"
    :style="{ background: canGoBack ? '#161827' : '#1E2135' }"
    ref="header"
    @click="changeHeight"
  >
    <template v-if="canGoBack">
      <img
        class="logo"
        src="@/assets/logLogo_cn.png"
        alt=""
        @click="backHandler"
        v-if="wdWidth >= 900 || (wdWidth <= 900 && !token)"
      />
      <img class="user_notice" src="@/assets/notice.svg" alt="" v-else />
      <div class="search" v-if="wdWidth >= 900 || (wdWidth <= 900 && token)">
        <img
          class="search_logo"
          src="@/assets/v2/h5headersearch.png"
          alt=""
          v-if="wdWidth < 900"
        />
        <input
          class="search_input"
          type="text"
          v-model="keyword"
          :placeholder="`${$t('Youtube.searchButton')}...`"
          @keyup.enter="toList()"
        />
        <img
          class="search_logo"
          src="@/assets/search.svg"
          alt=""
          @click="toList()"
          v-if="wdWidth >= 900"
        />
      </div>

      <div class="user" v-if="userInfo?.user_level && wdWidth >= 900">
        <img
          class="user_notice"
          src="@/assets/uploding.png"
          alt=""
          @click="touploadvideo"
        />
        <img class="user_notice" src="@/assets/notice.svg" alt="" />
        <div class="tooltip" @mousemove="languagetips = true">
          <span class="iconfont aicean-lang user_notice" @mousemove="languagetips = true"></span>
          <div
            class="dui-tips"
            :style="{ display: languagetips ? 'block' : 'none' }"
            @mouseleave="languagetips = false"
          >
            <div v-for="(item,index) in languagelist" :key="index"  @click="changeLang(item)" class="langbox_item" :class="language == item.value ? 'langbox_item_active' : ''">
              {{ item.title }} <span v-if="language == item.value" class="iconfont aicean-langactive"></span>
            </div>
          </div>
        </div>
        <div class="user_info">
          <div class="">
            <span class="user_info_text1">HELLO!</span>
            <span class="user_info_text2">{{ chain | chainFilter }}</span>
          </div>
          <div class="user_info_text3">{{ name }}</div>
        </div>
        <div class="user_gold" @mouseenter="btnHandler(1)">
          <img class="user_gold_img1" src="@/assets/goldv2.png" alt="" />
          <span>{{ numberWithCommas(parseInt(Number(SPK) * 100) / 100) }}</span>
          <img
            class="user_gold_img2"
            :src="avatar || require('@/assets/default-avatar.png')"
            alt=""
          />
        </div>
        <template>
          <div
            class="pop"
            @mouseleave="btnHandler(2)"
            @click.stop=""
            :style="{
              height: `${showPop ? 32.5 : 0}rem`,
              border: `${showPop ? '0.08rem solid #313342' : 'none'}`,
            }"
          >
            <div class="flex pop_user" @click.stop="tomyportfolio">
              <img
                class="pop_userimg"
                :src="avatar || require('@/assets/default-avatar.png')"
                alt=""
              />
              <div>
                <div class="pop_username">{{ chain | chainFilter }}</div>
                <div class="flex pop_text1">
                  <span
                    >{{ $t('header.Video') }}
                    {{ userInfo.post_num | numFilter }}</span
                  >
                  <span
                    >{{ $t('header.Fans') }}
                    {{ userInfo.be_follow_num | numFilter }}</span
                  >
                  <span
                    >{{ $t('header.Follow') }}
                    {{ userInfo.follow_num | numFilter }}</span
                  >
                </div>
                <div class="pop_line">
                  <div
                    class="pop_line_box"
                    :style="{
                      width: `${
                        (parseInt(userInfo?.user_level?.now_point) /
                          parseInt(userInfo?.user_level?.max_point)) *
                        100
                      }%`,
                    }"
                  ></div>
                </div>
                <div class="flex flex-jsb flex-ac">
                  <div class="pop_text2">
                    LV{{ userInfo.user_level.level_no }}
                  </div>
                  <div class="pop_text3">
                    <span>{{ parseInt(userInfo?.user_level?.now_point) }}</span
                    >/{{ userInfo?.user_level?.max_point }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-jsb flex-ac">
              <div class="pop_text4">{{ $t('header.Points') }}</div>
              <div class="pop_text5" @click="topoints">
                {{ $t('header.Record')
                }}<span class="iconfont aicean-arrow"></span>
              </div>
            </div>
            <div class="pop_box1" @click="topoints">
              <div class="pop_box1_text1">
                {{ numberWithCommas(parseInt(Number(SPK) * 100) / 100) }}
              </div>
              <div class="pop_box1_box1">
                {{ $t('header.Today') }}+{{
                  parseInt(WalletDetail.daily_add_point || 0)
                }}
              </div>
            </div>
            <div class="pop_item" @click="tomyportfolio">
              <div class="pop_item_text">
                <span class="iconfont aicean-yaoqing"></span
                >{{ $t('header.Bind_account') }}
              </div>
              <span class="iconfont aicean-arrow"></span>
            </div>
            <div class="pop_item">
              <div class="pop_item_text">
                <span class="iconfont aicean-a-bianzu20beifen"></span
                >{{ $t('header.Invite') }}
              </div>
              <div
                class="pop_item_btn"
                v-loading="Invitationloding"
                element-loading-background="rgba(0,0,0,0.1)"
                @click="copyInvitation"
              >
                {{ $t('header.Copy') }}
              </div>
            </div>
            <div class="pop_item" @click="logout">
              <div class="pop_item_text">
                <span class="iconfont aicean-logout"></span
                >{{ $t('header.Log_Out') }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="user" v-if="!token">
        <div class="user_login" @click.stop="btnHandler">
          {{ $t('header.Log_In') }}
        </div>
        <div class="user_signup" @click.stop="btnHandler">
          {{ $t('header.Sign_up') }}
        </div>
      </div>
      <!-- <div class="user">
      <div class="user_taice">236TAICE</div>
      <div class="user_uploder">上传</div>
      <div class="user_head">
        <img :src="require('@/assets/default-avatar.png')" alt="" />
      </div>
    </div> -->
      <div class="user" v-if="wdWidth <= 900 && token" @click="drawer = true">
        <img
          class="user_notice"
          src="@/assets/uploding.png"
          alt=""
          @click.stop="touploadvideo"
        />
        <img
          class="user_head"
          :src="require('@/assets/default-avatar.png')"
          alt=""
        />

        <div class="user_name">
          {{ $t('header.Welcome') }}, <span>{{ name }}</span>
        </div>
      </div>
      <el-drawer
        v-if="wdWidth <= 900 && userInfo?.user_level"
        direction="ltr"
        :withHeader="false"
        :show-close="false"
        :append-to-body="true"
        :modal-append-to-body="false"
        :wrapperClosable="true"
        :visible.sync="drawer"
        size="27.5rem"
      >
        <div class="pop">
          <div class="pop_close" @click="drawer = false">
            <span class="iconfont aicean-arrow"></span>
          </div>
          <div class="flex pop_user" @click="tomyportfolio">
            <img
              class="pop_userimg"
              :src="avatar || require('@/assets/default-avatar.png')"
              alt=""
            />
            <div>
              <div class="pop_username">{{ chain | chainFilter }}</div>
              <div class="flex pop_text1">
                <span
                  >{{ $t('header.Video') }}
                  {{ userInfo.post_num | numFilter }}</span
                >
                <span
                  >{{ $t('header.Fans') }}
                  {{ userInfo.be_follow_num | numFilter }}</span
                >
                <span
                  >{{ $t('header.Follow') }}
                  {{ userInfo.follow_num | numFilter }}</span
                >
              </div>
              <div class="pop_line">
                <div
                  class="pop_line_box"
                  :style="{
                    width: `${
                      (parseInt(userInfo?.user_level?.now_point) /
                        parseInt(userInfo?.user_level?.max_point)) *
                      100
                    }%`,
                  }"
                ></div>
              </div>
              <div class="flex flex-jsb flex-ac">
                <div class="pop_text2">
                  LV{{ userInfo.user_level.level_no }}
                </div>
                <div class="pop_text3">
                  <span>{{ parseInt(userInfo?.user_level?.now_point) }}</span
                  >/{{ userInfo?.user_level?.max_point }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-jsb flex-ac">
            <div class="pop_text4">{{ $t('header.Points') }}</div>
            <div class="pop_text5" @click="topoints">
              {{ $t('header.Record')
              }}<span class="iconfont aicean-arrow"></span>
            </div>
          </div>
          <div class="pop_box1">
            <div class="pop_box1_text1">
              {{ numberWithCommas(parseInt(Number(SPK) * 100) / 100) }}
            </div>
            <div class="pop_box1_box1">
              {{ $t('header.Today') }}+{{
                parseInt(WalletDetail.daily_add_point || 0)
              }}
            </div>
          </div>
          <div class="pop_item">
            <div class="pop_item_text" @click="tomyportfolio">
              <span class="iconfont aicean-yaoqing"></span
              >{{ $t('header.Bind_account') }}
            </div>
            <span class="iconfont aicean-arrow"></span>
          </div>
          <div class="pop_item">
            <div class="pop_item_text">
              <span class="iconfont aicean-a-bianzu20beifen"></span
              >{{ $t('header.Invite') }}
            </div>
            <div class="pop_item_btn">{{ $t('header.Copy') }}</div>
          </div>
          <div class="pop_item" @click="tochangelanguage">
            <div class="pop_item_text" >
              <span class="iconfont aicean-lang"></span
              >{{ getlanguage }}
            </div>
            <span class="iconfont aicean-arrow"></span>
          </div>
          <div class="pop_item" @click="changelogout">
            <div class="pop_item_text">
              <span class="iconfont aicean-logout"></span
              >{{ $t('header.Log_Out') }}
            </div>
          </div>
        </div>
      </el-drawer>
    </template>
    <template v-else>
      <div class="navbar">
        <span class="iconfont aicean-arrow" @click="goBack"></span>
        <div>
          {{ $t(`router.${$route.name}`) }}
          <!-- {{
            `${$route.name?.charAt(0).toUpperCase()}${$route.name.slice(1)}` ||
            ' '
          }} -->
        </div>
      </div>
    </template>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { WalletEthers } from '@/utils/walletconnect2'
import { getAccount, watchAccount, disconnect } from '@wagmi/core'
import walletSign from '@/components/walletSign/index.js'

export default {
  inject: ['taskRefresh'],
  data() {
    return {
      drawer: false,
      canGoBack: true,
      keyword: '',
      tagList: [
        'Home',
        'Discover',
        'Task',
        // 'Creators',
        'Leaderboard',
        'Referral',
        'Campaign',
      ],
      activeIndex: 0,
      showFlag: false,
      lang: 'en',
      langList: [
        { icon: '🇨🇳', lang: 'zh' },
        { icon: '🇺🇸', lang: 'en' },
      ],
      // popList: ['Profile', 'Wallet', 'Referral', 'Logout'],
      popList: [
        {
          title: 'Profile',
          router: 'Profile',
          url: require('@/assets/profile.svg'),
        },
        {
          title: 'My Videos',
          router: 'creation',
          url: require('@/assets/videos.svg'),
        },
        {
          title: 'My Collections',
          router: 'Collect',
          url: require('@/assets/collections.svg'),
        },
        {
          title: 'Performance',
          router: 'Analyze',
          url: require('@/assets/performance.svg'),
        },
        {
          title: 'Setting',
          router: 'ProfileEdit',
          url: require('@/assets/setting.svg'),
        },
        {
          title: 'Logout',
          router: 'Logout',
          url: require('@/assets/logout.svg'),
        },
      ],
      showPop: false,
      popCount: 5,
      unWatch: null,
      WalletDetail: {},
      Invitationloding: false,
      languagelist: [
        {
                    title: 'English',
                    value: 'en',
                },
                {
                    title: '简体中文',
                    value: 'cn',
                },                
                {
                    title: '繁体中文',
                    value: 'tw',
                },
      ],
      language: 'en',
      languagetips: false,
    }
  },
  filters: {
    chainFilter(val) {
      return val.slice(0, 6) + '...' + val.slice(val.length - 6, val.length - 1)
    },
    numFilter(val) {
      if (val > 1000) return parseInt(val / 1000) + 'K+'
      return val
    },
  },
  created() {
    if (localStorage.getItem('lang')) {
      this.language = localStorage.getItem('lang')
    } else {
      localStorage.setItem('lang', this.language)
    }
  },
  async mounted() {
    console.log(this.$router)
    this.updateCanGoBack()
    window.addEventListener('scroll', this.scrollHanlder)
    window.addEventListener('resize', this.updateCanGoBack)
    try {
      if (this.token) {
        let res = await this.$request({
          path: 'user/getWalletDetail',
          data: { currency_key: 'taice' },
        })
        if (res.code == 0) {
          this.WalletDetail = res.data
        }
      }
    } catch {}
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHanlder)
    window.removeEventListener('resize', this.updateCanGoBack)
  },
  methods: {
    ...mapActions('user', ['logout']),
    tochangelanguage(){
      this.$router.push({path:'/v2/changelanguage'})
    },
    changeLang(e) {
            this.language = e.value
            localStorage.setItem('lang', e.value)
            this.$i18n.locale = e.value
            this.$router.go(0)
        },
    touploadvideo() {
      this.$router.push({ name: 'Youtube' })
    },
    tomyportfolio() {
      this.drawer = false
      this.showPop = false
      this.$router.push({
        path: '/V2/My',
      })
    },
    topoints() {
      this.$router.push({
        path: '/V2/points',
      })
    },
    async copyInvitation() {
      this.Invitationloding = true
      await this.$request({
        path: 'user/invitationDetail',
      }).then(async (e) => {
        console.log(e)
        if (e.code == 0) {
          let invite_code = e.data.user.invite_code
          let InvitationURL = ''
          if (window?.TelegramWebviewProxy) {
            InvitationURL = `${process.env.VUE_APP_TG_URL}?startapp=${invite_code}`
          } else {
            InvitationURL = `${window.location.origin}?invite_code=${invite_code}`
          }
          await this.$copyText(InvitationURL)
          this.$message({
            message: this.$t('common.copy'),
            type: 'success',
            showClose: true,
            customClass: 'myMessage',
          })
          this.Invitationloding = false
        }
      })
    },
    changelogout() {
      this.drawer = false
      this.showPop = false
      this.logout()
    },
    updateCanGoBack() {
      this.canGoBack =
        this.wdWidth <= 900
          ? [
              '/Home',
              '/V2/task',
              '/V2/Videos',
              '/V2/My',
              '/customized',
              '/V2/videos',
            ].filter((item) => {
              return this.$route.path == item
            }).length > 0
            ? true
            : this.$router.history.length > 1
          : true
    },
    goBack() {
      this.$router.go(-1)
    },
    numFilter(num = 0) {
      num = Number(num)
      if (num > 1000000) return parseInt(num / 10).toLocaleString() + '+'
      return parseInt(num).toLocaleString()
    },
    backHandler() {
      if (this.$route.name === 'Home') this.$router.go(0)
      let backlen = history.length
      window.history.go(-backlen)
      this.$router.replace({ name: 'Home' })
    },
    toList() {
      this.$router.replace({
        name: 'Discover',
        query: {
          keyword: this.keyword,
        },
      })
    },
    async test() {
      this.walletObj.getAccount()
      // this.$router.push({name:'Analyze'})
      // console.log('----test---')
      // this.taskRefresh()
    },
    touploadvideo() {
      this.$router.push({ name: 'Youtube' })
    },
    tosearch() {
      this.$router.push({ name: 'Discover' })
    },
    changeTag(index) {
      if ([2, 4].includes(index) && !this.token) {
        // this.$message({
        //   message: 'Please Login',
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
        this.btnHandler()
        return
      }

      if (this.showPop) this.showPop = false
      if (
        this.tagList[this.activeIndex] == this.$route.name &&
        this.activeIndex == index
      )
        return
      this.activeIndex = index
      if (this.isMoblie && this.showFlag) this.showFlag = false
      this.$store.commit('common/CLEAR_KEEP_ROUTES')
      this.$router.push({ name: this.tagList[index] })
    },

    btnHandler(is) {
      if (!this.$store.state.user.token)
        return this.$store.commit('user/LOG_STATE', true)
      if (is === 1) {
        this.showPop = true
      } else if (is === 2) {
        this.showPop = false
      } else {
        this.showPop = !this.showPop
      }

      // this.activeIndex=-1
    },
    checkWallet() {
      return {
        width: this.token ? 'auto' : 0,
        minWidth: this.token ? '8rem' : 0,
        padding: this.token ? '.94rem 1.88rem ' : '.94rem 0',
      }
    },
    changeHeight() {
      if (this.showPop) this.showPop = false
      if (!this.isMoblie) return
      this.showFlag = false
    },
    ChangeLang(lang) {
      this.lang = lang
    },
    jumpRouter(name) {
      if (name == 'Logout') {
        this.showPop = false
        return this.logout()
      }
      if (name == 'creation') {
        this.$router.replace({ path: `/SelfProfile/creation` })
        return
      }
      console.log('测试——', this.$route.name)
      if (
        (name != 'Profile' && name == this.$route.name) ||
        (name == 'Profile' && this.$route.name == 'SelfProfile')
      )
        return (this.showPop = false)
      this.showPop = false
      if (name == 'Profile') return this.$router.push({ name: 'SelfProfile' })
      this.$router.push({ name: name })
    },
    jumpBeta() {
      if ('Beta' == this.$route.name) return
      if (this.showPop) this.showPop = false
      if (this.showFlag && this.isMoblie) this.showFlag = false
      console.log('这是下标---', this.activeIndex)
      this.activeIndex = 5
      console.log('这是下标---', this.activeIndex)
      this.$forceUpdate()
      this.$store.commit('common/CLEAR_KEEP_ROUTES')
      this.$router.push({ name: 'Beta' })
    },
    async bindHandler() {
      try {
        const { isConnected } = getAccount()
        if (!isConnected) {
          await WalletEthers.modal.openModal()
          if (!this.unWatch) {
            this.unWatch = watchAccount(async (account) => {
              if (account.address && account.isConnected) walletSign.install({})
            })
          }
        } else walletSign.install({})
      } catch (err) {
        console.log('err', err)
        this.$message({
          message:
            err?.reason ||
            err?.data?.message ||
            err?.message ||
            err?.msb ||
            err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        if (this.unWatch) {
          this.unWatch()
          this.unWatch = null
        }
        await disconnect()
      }
    },
    jumpHistory() {
      // this.$router.push({ name: 'pointHistory' })
      this.$router.push({ name: 'ExchangeHistory' })
      return
      if (!this.chain && this.popCount) {
        this.bindHandler()
        this.popCount--
      } else if (this.chain) {
        this.$router.push({ name: 'pointHistory' })
      }
    },
    scrollHanlder(e) {
      // console.log('滚动----');
      this.$refs['header'].style.background = `rgba(31,33,53,${
        window.scrollY / (window.innerHeight * 0.15)
      })`
    },
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    backHandler() {
      // if (!this.token) {
      //   this.$router.replace({ name: 'Started' })
      //   return
      // }
      if (this.$route.name === 'Home') this.$router.go(0)
      let backlen = history.length
      window.history.go(-backlen)
      this.$router.replace({ name: 'Home' })
    },
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'wdWidth',
      'isMoblie',
      'token',
      'avatar',
      'name',
      'chain',
      'SPK',
      'walletObj',
      'SearchKey',
    ]),
    getlanguage() {
      return this.languagelist.find((item) => {
        return item.value == this.language
      }).title
    },
    shrinkHeight() {
      return this.wdWidth <= 900 ? '6.38rem' : '5.81rem'
    },
  },
  watch: {
    token(nval, oval) {
      if (nval) {
        this.showPop = false
      }
    },
    $route(to, from) {
      if (new URLSearchParams(window.location.search).get('keyword')) {
        this.keyword = new URLSearchParams(window.location.search).get(
          'keyword'
        )
      } else {
        this.keyword = ''
      }
      this.updateCanGoBack()
    },
    wdWidth: {
      handler(val) {
        // console.log('发生变化')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@include fade(0.2);
div {
  font-family: SVN-GilroyMedium;
}
.langbox {
    margin-top: 1.25rem;
    background: #1F2135;
    border-radius: 0.67rem;
    border: 0.08rem solid #313342;
    color: #fff;

    &_item {
      cursor: pointer;
    height: 3.92rem;
        font-family: SVN-GilroyMedium;
        font-size: 1.33rem;
        padding: 0 1.25rem;
        display: flex;
        align-items: center;
        border-bottom: 0.08rem solid #313342;

        span {
          color: #EDBF4F;
            margin-left: auto;
            font-size: 1rem;
        }

        &_active {
            color: #EDBF4F;
        }
    }
}
.dui-tips {
  width: 11.67rem;
  box-sizing: border-box;
  position: absolute;
  margin-top: 0.8rem;
  margin-left: 0.75rem;
  text-align: center;
  border: 0.08rem solid #313342;
  border-radius: 0.83rem;
  background-color: #1f2135;
  font-size: 1.17rem;
  color: #999999;
  line-height: 1.67rem;
  text-align: left;
}
.header {
  position: fixed;
  top: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  padding: 0 4.17rem 0 4.5rem;
  height: 5rem;
  background: #161827;
  backdrop-filter: blur(0.25rem);
}
.logo {
  width: 10rem;
  height: 2.5rem;
  cursor: pointer;
}
.search {
  flex: 1;
  max-width: 33.33rem;
  padding: 0 1.25rem 0;
  width: 100%;
  height: 3rem;
  border-radius: 1.5rem;
  border: 0.08rem solid #303030;
  display: flex;
  align-items: center;
  &_input {
    flex: 1;
    font-size: 1.33rem;
    background: transparent;
    box-shadow: none;
    border: none;
    color: #ffffff;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #888888;
    }
  }
  &_logo {
    cursor: pointer;
    width: 1.54rem;
    height: 1.54rem;
  }
}
.user {
  position: relative;
  width: 10rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  &_notice {
    cursor: pointer;
    // width: 1.5rem;
    color: #fff;
    height: 1.67rem;
    margin-right: 1.33rem;
  }
  &_info {
    padding-left: 1.17rem;
    border-left: 0.17rem solid #1f2135;
    text-align: left;
    &_text1 {
      font-family: SVN-GilroyMedium;
      font-size: 1rem;
      color: #ffffff;
      margin-right: 0.83rem;
    }
    &_text2 {
      font-family: SVN-Gilroy;
      font-size: 1rem;
      color: rgba(153, 153, 153, 0.68);
    }
    &_text3 {
      white-space: nowrap;
      font-family: SVN-Gilroy;
      font-size: 1.17rem;
      color: #edbf4f;
    }
  }
  &_gold {
    margin-left: 2.3rem;
    // width: 12.67rem;
    height: 3.33rem;
    border-radius: 1.67rem;
    border: 0.08rem solid #edbf4f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #edbf4f;
    font-size: 1.5rem;
    font-family: SVN-GilroyBold;
    &_img1 {
      margin: 0 0.42rem;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
    }
    &_img2 {
      margin-left: 1.22rem;
      width: 3.17rem;
      height: 3.17rem;
      border-radius: 50%;
    }
  }
  &_login {
    cursor: pointer;
    margin-right: 0.83rem;
    flex-shrink: 0;
    width: 10.75rem;
    height: 3.33rem;
    background: #1f2135;
    border-radius: 1.67rem;
    border: 0.08rem solid #313342;
    color: #ffffff;
    font-size: 1.33rem;
    font-family: SVN-GilroyMedium;
    line-height: 3.33rem;
    text-align: center;
  }
  &_signup {
    cursor: pointer;
    flex-shrink: 0;
    width: 10.75rem;
    height: 3.33rem;
    background: #edbf4f;
    border-radius: 1.67rem;
    font-size: 1.33rem;
    color: #161827;
    font-family: SVN-GilroyMedium;
    line-height: 3.33rem;
    text-align: center;
  }
  &_taice {
    padding: 0 2.25rem;
    height: 3rem;
    background: #edbf4f;
    border-radius: 1.5rem;
    line-height: 3rem;
    font-size: 1.33rem;
    text-align: center;
  }
  &_uploder {
    // width: 3.94rem;
    white-space: nowrap;
    margin: 0 0.9rem 0 1.25rem;
    padding: 0 1.25rem;
    height: 3rem;
    border-radius: 1.5rem;
    border: 0.08rem solid #303030;
    font-size: 1.33rem;
    color: #ffffff;
    line-height: 3rem;
    text-align: center;
  }
  &_head {
    width: 3rem;
    height: 3rem;
    // border: 0.06rem solid rgba(255, 255, 255, 0.56);
    border-radius: 50%;
    img {
      width: 3rem;
      height: 3rem;
    }
  }
}
.pop {
  position: absolute;
  top: 5rem;
  right: 0;
  width: 29.17rem;
  height: 0rem;
  background: #1f2135;
  padding: 0 1.67rem;
  border-radius: 0.83rem;
  border: 0.08rem solid #313342;
  box-sizing: border-box;
  text-align: left;
  color: #fff;
  overflow: hidden;
  transition: height 0.3s;
  backdrop-filter: blur(0.875rem);
  &_user {
    cursor: pointer;
    padding-top: 1.67rem;
  }
  &_userimg {
    width: 4.58rem;
    height: 4.58rem;
    border-radius: 50%;
    margin-right: 0.92rem;
    margin-bottom: 3.75rem;
  }
  &_username {
    font-size: 1.5rem;
    font-family: SVN-GilroyMedium;
    line-height: 1.92rem;
    margin-bottom: 0.33rem;
  }
  &_text1 {
    color: #999999;
    font-size: 1rem;
    line-height: 1.42rem;
    margin-bottom: 0.92rem;
    span {
      margin-right: 1.25rem;
    }
  }
  &_line {
    width: 20.42rem;
    height: 0.25rem;
    background: #313342;
    border-radius: 0.17rem;
    margin-bottom: 0.42rem;
    &_box {
      width: 7.67rem;
      height: 0.25rem;
      background: #edbf4f;
      border-radius: 0.17rem;
    }
  }
  &_text2 {
    font-weight: 500;
    font-size: 1rem;
    color: #edbf4f;
    line-height: 1.42rem;
  }
  &_text3 {
    font-size: 1rem;
    line-height: 1.42rem;
    span {
      color: #edbf4f;
    }
  }
  &_text4 {
    font-weight: 600;
    font-size: 1.33rem;
    line-height: 1.83rem;
  }
  &_text5 {
    cursor: pointer;
    font-size: 1.17rem;
    color: #999999;
    line-height: 1.67rem;
    span {
      margin-left: 0.42rem;
      vertical-align: text-top;
      font-size: 0.83rem;
    }
  }
  &_box1 {
    cursor: pointer;
    margin-top: 0.83rem;
    margin-bottom: 0.31rem;
    width: 25.83rem;
    height: 6.25rem;
    border-radius: 0.83rem;
    background: url('@/assets/v2/mytaice.png') right bottom no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    &_text1 {
      margin-left: 1.75rem;
      font-family: SVN-GilroySemiBold;
      font-size: 2.5rem;
      line-height: 3.17rem;
    }
    &_box1 {
      // width: 6.33rem;
      padding: 0 0.8rem;
      height: 1.92rem;
      background: #edbf4f;
      border-radius: 0.92rem 1rem 1rem 0.33rem;
      color: #161827;
      font-size: 0.83rem;
      font-family: SVN-GilroySemiBold;
      text-align: center;
      line-height: 1.92rem;
      margin-left: 0.83rem;
    }
  }
  &_item {
    cursor: pointer;
    width: 100%;
    height: 4.17rem;
    border-bottom: 0.08rem solid #313342;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_text {
      font-size: 1.33rem;
      line-height: 1.67rem;
      font-family: SVN-GilroyMedium;
      span {
        font-size: 1.5rem !important;
        margin-right: 0.83rem;
        line-height: 1.67rem;
      }
    }
    span {
      font-size: 1.17rem;
    }
    &_btn {
      overflow: hidden;
      width: 7.92rem;
      height: 2.83rem;
      background: #edbf4f;
      border-radius: 1.5rem;
      display: flex;
      justify-content: center;
      color: #161827;
      font-size: 1.17rem;
      align-items: center;
    }
  }
  &_item:last-child {
    border-bottom: none;
  }
}
.navbar {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-left: 1.25rem;
    rotate: 180deg;
    font-size: 1.33rem;
    color: #fff;
  }
  div {
    width: 100%;
    flex: 1;
    text-align: center;
    color: #ffffff;
    line-height: 1.83rem;
    font-size: 1.33rem;
  }
}
@media screen and (max-width: 56.25rem) {
  .pop {
    position: relative;
    top: 0;
    right: 0;
    width: 27.5rem;
    height: 100%;
    background: #1f2135;
    padding: 0 1.25rem;
    border-radius: 0;
    border: 0.08rem solid #313342;
    box-sizing: border-box;
    text-align: left;
    color: #fff;
    overflow: hidden;
    transition: height 0.3s;
    backdrop-filter: blur(0.875rem);
    &_close {
      margin: 1.58rem 0 2rem;
      span {
        display: inline-block;
        rotate: 180deg;
        font-size: 1.22rem;
        text-align: left;
      }
    }
    &_user {
      padding-top: 0rem;
    }
    &_userimg {
      flex-shrink: 0;
      width: 4.58rem;
      height: 4.58rem;
      border-radius: 50%;
      margin-right: 0.92rem;
      margin-bottom: 3.75rem;
    }
    &_username {
      font-size: 1.5rem;
      font-family: SVN-GilroyMedium;
      line-height: 1.92rem;
      margin-bottom: 0.33rem;
    }
    &_text1 {
      color: #999999;
      font-size: 1rem;
      line-height: 1.42rem;
      margin-bottom: 0.92rem;
      span {
        margin-right: 1.25rem;
      }
    }
    &_line {
      width: 19.5rem;
      height: 0.25rem;
      background: #313342;
      border-radius: 0.17rem;
      margin-bottom: 0.42rem;
      &_box {
        width: 7.67rem;
        height: 0.25rem;
        background: #edbf4f;
        border-radius: 0.17rem;
      }
    }
    &_text2 {
      font-weight: 500;
      font-size: 1rem;
      color: #edbf4f;
      line-height: 1.42rem;
    }
    &_text3 {
      font-size: 1rem;
      line-height: 1.42rem;
      span {
        color: #edbf4f;
      }
    }
    &_text4 {
      font-weight: 600;
      font-size: 1.33rem;
      line-height: 1.83rem;
    }
    &_text5 {
      font-size: 1.17rem;
      color: #999999;
      line-height: 1.67rem;
      span {
        margin-left: 0.42rem;
        vertical-align: text-top;
        font-size: 0.83rem;
      }
    }
    &_box1 {
      margin-top: 0.83rem;
      margin-bottom: 0.31rem;
      width: 25rem;
      height: 6.25rem;
      border-radius: 0.83rem;
      background: url('@/assets/v2/mytaice.png') right bottom no-repeat;
      background-size: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      &_text1 {
        margin-left: 1.75rem;
        font-family: SVN-GilroySemiBold;
        font-size: 2.5rem;
        line-height: 3.17rem;
      }
      &_box1 {
        width: 6.33rem;
        height: 1.92rem;
        background: #edbf4f;
        border-radius: 0.92rem 1rem 1rem 0.33rem;
        color: #161827;
        font-size: 0.83rem;
        font-family: SVN-GilroySemiBold;
        text-align: center;
        line-height: 1.92rem;
        margin-left: 0.83rem;
      }
    }
    &_item {
      cursor: pointer;
      width: 100%;
      height: 4.17rem;
      border-bottom: 0.08rem solid #313342;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_text {
        font-size: 1.33rem;
        line-height: 1.67rem;
        font-family: SVN-GilroyMedium;
        span {
          font-size: 1.5rem !important;
          margin-right: 0.83rem;
          line-height: 1.67rem;
        }
      }
      span {
        font-size: 1.17rem;
      }
      &_btn {
        width: 7.92rem;
        height: 2.83rem;
        background: #edbf4f;
        border-radius: 1.5rem;
        display: flex;
        justify-content: center;
        color: #161827;
        font-size: 1.17rem;
        align-items: center;
      }
    }
    &_item:last-child {
      border-bottom: none;
    }
  }
  .header {
    padding: 0 1rem;
    height: 4.17rem;
  }
  .logo {
    width: 6.67rem;
    height: 1.67rem;
  }
  .user {
    width: auto;
    margin-left: 0.42rem;
    &_login {
      margin-right: 0.75rem;
      width: 5.5rem;
      height: 2.17rem;
      border-radius: 1.08rem;
      font-size: 1rem;
      line-height: 2.17rem;
    }
    &_signup {
      width: 5.5rem;
      height: 2.17rem;
      border-radius: 1.08rem;
      font-size: 1rem;
      line-height: 2.17rem;
    }
    &_notice {
      // width: 1.33rem;
      height: 1.5rem;
      margin-right: 0.4rem;
    }
    &_head {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.43rem;
    }
    &_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 13rem;
      // white-space: nowrap;
      font-size: 1.17rem;
      color: #ffffff;
      line-height: 1.5rem;
      font-family: SVN-GilroyMedium;
      span {
        color: #edbf4f;
      }
    }
  }
  .search {
    width: 9.83rem;
    margin-right: auto;
    flex: none;
    flex-shrink: 1;
    height: 2.5rem;
    padding: 0 0.83rem;
    box-sizing: border-box;
    &_logo {
      width: 1.17rem;
      height: 1.17rem;
      margin-right: 0.83rem;
    }
    &_input {
      width: 5rem;
      font-size: 1rem;
      &::placeholder {
        color: #313342;
      }
    }
  }
}
</style>
