<template>
  <div
    class="box_wrapper flex flex-jc"
    @click.stop="close"
    :style="[wrapperStyle]"
  >
    <div
      class="invitation"
      :style="[boxSytle]"
      @click.stop=""
      v-loading="loading"
      element-loading-background="rgba(0,0,0,.6)"
    >
      <div class="invitation_box1">
        <div class="invitation_box1_text1">Referral</div>
        <img
          @click="close"
          class="invitation_box1_btn"
          src="@/assets/v2/close.png"
          alt=""
        />
      </div>
      <div class="invitation_box2">
        <div class="invitation_box2_item" @click.stop="openTab(0, 'Facebook')">
          <img
            class="invitation_box2_item_img"
            src="@/assets/facebookIcon.svg"
            alt=""
          />
          <div class="invitation_box2_item_text">Facebook</div>
        </div>
        <div class="invitation_box2_item" @click.stop="openTab(1, 'Twitter')">
          <img
            class="invitation_box2_item_img"
            src="@/assets/v2/Twitter.png"
            alt=""
          />
          <div class="invitation_box2_item_text">Twitter</div>
        </div>
        <div class="invitation_box2_item" @click.stop="openTab(2, 'WhatsApp')">
          <img
            class="invitation_box2_item_img"
            src="@/assets/v2/WhatsApp.png"
            alt=""
          />
          <div class="invitation_box2_item_text">WhatsApp</div>
        </div>
        <div class="invitation_box2_item" @click.stop="openTab(3, 'Email')">
          <img
            class="invitation_box2_item_img"
            src="@/assets/v2/Email.png"
            alt=""
          />
          <div class="invitation_box2_item_text">Email</div>
        </div>
      </div>
      <div class="invitation_box3">
        <div class="invitation_box3_input">
          {{ InvitationURL }}
        </div>
        <div class="invitation_box3_btn" @click="copyInvitation">Copy</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'invitation',
  props: {
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      description:
        "Come join me and get rewarded TAICE while you watch videos on AICEAN. Let's experience the future of YouTube together!",
      InvitationURL: '',
      invite_code: '',
    }
  },
  watch: {
    showinvitation(nval, oval) {
      if (nval) {
        this.getinvitationDetail()
      }
    },
  },
  model: {
    // prop: 'show',
    // event: 'change',
  },
  methods: {
    async getinvitationDetail() {
      this.loading = true
      await this.$request({
        path: 'user/invitationDetail',
      }).then((e) => {
        console.log(e)
        if (e.code == 0) {
          this.invite_code = e.data.user.invite_code
          if (window?.TelegramWebviewProxy) {
            this.InvitationURL = this.invitationid
              ? `${process.env.VUE_APP_TG_URL}/videoDetail?id=${this.invitationid}&startapp=${this.invite_code}`
              : `${process.env.VUE_APP_TG_URL}?startapp=${this.invite_code}`
          } else {
            this.InvitationURL = this.invitationid
              ? `${window.location.origin}/videoDetail?id=${this.invitationid}&invite_code=${this.invite_code}`
              : `${window.location.origin}?invite_code=${this.invite_code}`
          }
        }
      })
      this.loading = false
    },
    async copyInvitation() {
      await this.$copyText(this.InvitationURL)
      this.$message({
        message: this.$t('common.copy'),
        type: 'success',
        showClose: true,
        customClass: 'myMessage',
      })
    },
    openTab(typeIndex, name) {
      let shareUrl = ''
      switch (typeIndex) {
        case 0:
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            this.InvitationURL + '&channel=Facebook'
          )}&quote=${encodeURIComponent(this.description)}`
          break
        // // case 0:shareUrl=`https://www.facebook.com/dialog/share?href=${this.url+'&channel=Facebook'}&display=popup`;break;
        case 1:
          shareUrl = `http://twitter.com/share?text=${encodeURIComponent(
            this.description
          )}&url=${encodeURIComponent(
            this.InvitationURL + '&channel=Twitter'
          )}&via=speckies_xyz`
          break
        // case 2:shareUrl=`https://api.whatsapp.com/send?text=${this.url+'&channel=WhatsApp'}&type=custom_url&app_absent=0`;break;
        case 2:
          if (
            typeof window.orientation !== 'undefined' ||
            navigator.userAgent.indexOf('IEMobile') !== -1
          ) {
            shareUrl = `https://wa.me/?text=${encodeURIComponent(
              this.InvitationURL +
                '&channel=WhatsApp&type=custom_url&app_absent=0'
            )}`
          } else {
            shareUrl = `https://api.whatsapp.com/send?text=${
              this.InvitationURL + '&channel=WhatsApp'
            }&type=custom_url&app_absent=0`
          }
          break
        case 3:
          shareUrl = `mailto:support?body=${encodeURIComponent(
            this.InvitationURL
          )}`
          break
      }
      console.log(shareUrl)
      // return
      this.$message({
        message: this.$t('common.share'),
        type: 'success',
        showClose: true,
        customClass: 'myMessage',
      })
      this.close()
      window.open(shareUrl, '_blank')
    },
    close() {
      this.InvitationURL = ''
      this.$store.commit('common/REMOVE_INVITATION_ID')
      // this.$emit('change', false)
      // this.show = false
    },
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'wdWidth',
      'token',
      'uuid',
      'invitationid',
      'showinvitation',
    ]),
    wrapperStyle() {
      return {
        opacity: this.showinvitation ? 1 : 0,
        zIndex: this.showinvitation ? 990 : -100,
      }
    },
    boxSytle() {
      return {
        transform: `scale(${this.showinvitation ? 1 : 0.1})`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  &_wrapper {
    width: 100%;
    height: 100vh;
    // overflow: scroll;
    background: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(0.875rem) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    transition: all 0.2s ease;
    // pointer-events: none;
  }
}
.invitation {
  margin: auto 0;
  width: 41.67rem;
  height: 23.83rem;
  background: #1f2135;
  border-radius: 1.5rem;
  backdrop-filter: blur(0.625rem);
  color: #fff;
  overflow: hidden;
  &_box1 {
    width: 41.67rem;
    height: 5.83rem;
    background: #ffbe00;
    border-radius: 1.5rem 1.5rem 0rem 0rem;
    padding: 0 2.08rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    &_text1 {
      font-family: SVN-GilroySemiBold;
      font-size: 2rem;
      color: #161827;
      line-height: 2.5rem;
    }
    &_btn {
      margin-left: auto;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  &_box2 {
    padding: 2.5rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(4, 6rem);
    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &_img {
        width: 4.58rem;
        height: 4.58rem;
        margin-bottom: 0.83rem;
      }
      &_text {
        font-family: SVN-Gilroy;
        font-size: 1.17rem;
        line-height: 1.5rem;
      }
    }
  }
  &_box3 {
    margin: auto;
    width: 37.5rem;
    height: 4.42rem;
    border-radius: 0.67rem;
    border: 0.08rem solid #4c4f65;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_input {
      text-align: left;
      margin-left: 1.25rem;
      background: transparent;
      border: none;
      flex: 1;
      font-family: SVN-GilroyMedium;
      font-size: 1.33rem;
      line-height: 1.67rem;
    }
    &_btn {
      cursor: pointer;
      margin-right: 0.83rem;
      width: 5.42rem;
      height: 2.92rem;
      background: #edbf4f;
      border-radius: 1.5rem;
      font-family: SVN-GilroyMedium;
      font-size: 1.17rem;
      color: #161827;
      line-height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 56.25rem) {
  .invitation {
    margin: auto 0;
    width: 26.25rem;
    height: 29.17rem;
    background: #1f2135;
    border-radius: 1rem;
    backdrop-filter: blur(0.625rem);
    &_box1 {
      width: 26.25rem;
      height: 3.83rem;
      background: #ffbe00;
      border-radius: 1rem 1rem 0rem 0rem;
      padding: 0 1.25rem;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      &_text1 {
        font-family: SVN-GilroySemiBold;
        font-size: 1.33rem;
        color: #161827;
        line-height: 1.67rem;
      }
      &_btn {
        margin-left: auto;
        width: 1.67rem;
        height: 1.67rem;
      }
    }
    &_box2 {
      padding: 2.5rem 2.5rem 1.67rem;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 1.67rem;
      &_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &_img {
          width: 4.58rem;
          height: 4.58rem;
          margin-bottom: 0.83rem;
        }
        &_text {
          font-family: SVN-Gilroy;
          font-size: 1.17rem;
          line-height: 1.5rem;
        }
      }
    }
    &_box3 {
      margin: auto;
      width: 23.75rem;
      height: 4.42rem;
      border-radius: 0.67rem;
      border: 0.08rem solid #4c4f65;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_input {
        text-align: left;
        margin-left: 1.25rem;
        background: transparent;
        border: none;
        flex: 1;
        font-family: SVN-GilroyMedium;
        font-size: 1.33rem;
        line-height: 1.67rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_btn {
        margin-right: 0.83rem;
        width: 5.42rem;
        height: 2.92rem;
        background: #edbf4f;
        border-radius: 1.5rem;
        font-family: SVN-GilroyMedium;
        font-size: 1.17rem;
        color: #161827;
        line-height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
