export default {
  guid: {
    New_user: '新用户引导',
    Welcome_to: '欢迎来到AIcean官方网站。点击任务中心查看您实时完成的任务',
    New_users: '新用户首次观看视频将获得200个TAICE，立即观看',
    Congratulations:
      '恭喜，您已完成第一个任务并获得200个TAICE。请继续挑战更多任务',
    Next: '下一步',
    Sure: '确定',
  },
  my: {
    mine:'我的',
    notyet:'未上榜',
    Edit: '编辑',
    My_TAICE: '我的TAICE',
    Earned_Rewards: '已获奖励',
    Invite_Friends: '邀请好友',
    Invitation: '邀请',
    Referral: '推荐',
    Facebook: '脸书',
    Twitter: '推特',
    WhatsApp: 'WhatsApp',
    Email: '电子邮件',
    Copy: '复制',
    Exclusive_Benefits: '专属福利',
    The_activities: '我参与的活动',
    Ranking_Activity: '排名活动',
    Your_ranking: '您本月的排名',
    This_month: '本月积分',
    Expected: '预期获得',
    Reason: '未上榜原因',
    Invite: '邀请',
    Watch: '观看',
    Upload: '上传',
    Only: '仅与前一名相差一位',
    Fraction: '分数',
    Potential: '潜在排名',
    If_the: '如果满足条件，您将排名',
    Rankings: '排名榜',
    My_Videos: '我的视频',
    View_All: '查看全部',
    No_data: '当前无数据',
    Watch_History: '观看历史',
    My_Collection: '我的收藏',
    My_Invitation: '我的邀请',
    Time: '时间',
    Address: '地址',
    State: '状态',
    Reward: '奖励',
    Points_Details: '积分详情',
    Details: '详情',
    Obtain: '获得总积分',
    History: '历史记录',
    A_total: '总计',
    items: '项',
    Today: '今天',
    Yesterday: '昨天',
    Total_Invited: '总共邀请的好友数',
    Get_Rewards: '获取奖励',
    Invitation_record: '邀请记录',
    User: '用户',
    Option: '选项',
  },
  videos: {
    Latest_Content: '最新内容',
    View_More: '查看更多',
    Currently: '当前热门',
    Recommended: '推荐频道',
    Collected: '已收藏',
    Collect: '收藏',
    Creator: '查看创作者',
    Share: '分享',
    Views: '观看',
  },
  Success: {
    message: '感谢您的注册！',
    description: `您现在已经踏上了将时间转化为收入的道路。开始观看视频，发现激动人心的机会，并让您的奖励不断增长。让我们珍惜每一分钟！`,
    action: '现在开始赚钱！',
  },
  Started: {
    tagline: '通过在线分享您喜欢的视频来赚钱！',
    accountCreationPrompt: '准备观看了吗？输入您的电子邮件以创建帐户',
    emailLabel: '电子邮件地址',
    registerButton: '立即注册',
    earningStepsHeader: '4步开始您的收益！',
    noSpendingRequired: '无需任何花费！',
    step1: '第1步. ',
    signUp: '注册',
    step2: '第2步. ',
    watchVideos: '观看视频',
    step3: '第3步.',
    shareVideos: '分享视频',
    step4: '第4步. ',
    inviteFriends: '邀请朋友',
    rankingHeader: '前4名-前103名:',
    winnersShare: '获胜者将分享',
    rankingDisplay: '根据排行榜显示',
    perUser: '每位用户。',
    businessModel: '与用户分享广告奖励的革命性商业模式！',
    earningPointsQuestion: '我如何赚取积分？',
    earningPointsAnswer: '观看视频后，系统将自动将积分添加到您的帐户。',
    redeemPointsQuestion: '我可以用积分兑换什么？',
    redeemPointsAnswer: '目前，积分可以兑换TSPK代币，未来将有更多礼品选项。',
    registrationFeeQuestion: '注册有费用吗？',
    registrationFeeAnswer: '注册和观看视频均免费，无需任何费用。',
    videoTimeLimitQuestion: '观看视频有时间限制吗？',
    videoTimeLimitAnswer:
      '没有时间限制；您可以在计算机、手机或iPad上随时观看视频。',
    checkPointsQuestion: '我如何查看我的积分？',
    checkPointsAnswer: '您可以在个人仪表板上查看当前的积分和代币。',
    issueSupportQuestion: '如果遇到问题，我应该怎么做？',
    issueSupportAnswer:
      '您可以通过我们的TG社区或X联系我们，我们将尽快为您提供帮助。',
    emailRequired: '请填写电子邮件',
    validEmail: '请输入正确的电子邮件地址',
  },
  Youtube: {
    selectAll: '全选',
    channelIdPlaceholder: '输入YouTube频道ID',
    searchButton: '搜索',
    moreOptions: '更多',
    category: '分类',
    publishImmediately: '发布（提交后立即发布）',
    cancelButton: '取消',
    submitButton: '提交',
    message: '同步成功！',
    error: '请至少选择一个视频',
    categoryError: '请选择一个分类',
    publishError: '请选择发布',
  },
  ProfileEdit: {
    avatarLabel: '头像',
    saveButton: '保存',
    nicknameLabel: '昵称:',
    nicknamePlaceholder: '填写昵称',
    walletLabel: '钱包',
    connectWalletButton: '连接钱包',
    walletAddressLabel: '您的钱包地址',
    googleEmailBindingLabel: 'Google邮箱绑定',
    bindingButton: '绑定',
    googleEmailLabel: '您的Google邮箱',
    emailLabel: '邮箱',
    emailPlaceholder: '填写邮箱',
    sendCodeButton: '发送验证码',
    verificationCodeLabel: '验证码',
    passwordLabel: '密码',
    socialLinksLabel: '社交链接',
    message: '获取用户信息失败',
    savesuccess: '保存成功',
    status: '发送验证码成功',
    codeStatus: '验证码不能为空',
    walletBindingPrompt: '请绑定钱包',
    passwordError: '密码不能为空',
    walletConnectionPrompt: '请连接钱包',
    requestError: '请求失败',
    emailChangeStatus: '更改邮箱成功',
    walletAlreadyBound: '用户已绑定钱包',
    avatar: '头像不能为空',
    nickname: '昵称不能为空',
    nicknamelonger: '昵称过长',
    twitter: 'Twitter不能为空',
    facebook: 'Facebook不能为空',
    youtube: 'YouTube不能为空',
    instagram: 'Instagram不能为空',
    emailValidator: '邮箱不能为空',
  },
  Analyze: {
    viewType: '视频',
    interactionType: '点赞',
    commentType: '评论',
    followerCount: '关注者数量',
    watchTimeHours: '观看时长（小时）',
    totalWatchTime: '视频发布以来的总观看时长。',
    avgViewDuration: '平均观看时长',
    avgWatchTime: '平均观看此视频的时间。',
  },
  Collect: {
    collections: '我的收藏夹',
  },
  selfProfileCreation: {
    profileName: '我的视频',
    action: '创建',
    platform: 'YouTube',
    filter: '全部',
    sortOrder: '最新',
    sortByViews: '最多观看',
    sortByLikes: '最多点赞',
    collections: '我的收藏夹',
  },
  component_user: {
    totalPoints: '总奖励：',
    totalViewingTime: '总观看时长：',
    videos: '视频',
    followers: '粉丝',
    following: '正在关注',
  },
  selfprofile_my: {
    History: '历史记录',
  },
  ExchangeHistory: {
    word: '说明',
    points: '奖励',
    timestamp: '时间',
    details: '更多',
  },
  videoDetail: {
    actionWatch: '观看',
    actionEarn: '赚取',
    actionView: '查看',
    followersCount: '关注数',
    actionShare: '分享',
    noTaskMessage: '当前没有任务',
    actionClaim: '领取',
    commentsCount: '评论数',
    loginAction: '登录',
    actionRelease: '发布',
    replyAction: '回复',
    totalReplies: '总回复数',
    loadMoreAction: '加载更多',
    noMoreCommentsMessage: '没有更多评论',
    incentiveMessage: '激励信息',
    recommendedLabel: '推荐',
    watchTodayAction: '今日观看',
    Follow: '关注',
    Unfollow: '取消关注',
    Copy: '复制',
    Like: '喜欢',
    Collect: '收藏',
    AddComments: '添加评论',
    Search: '搜索',
  },
  activityinfo: {
    signupMessage: '立即注册，开始赚钱！',
    eventEndTime: '活动结束时间：',
    rankingInfo: '第4名至第103名：根据排行榜显示。',
    participationSteps: '参与步骤',
    activityRules: '活动规则',
    rewardDistribution: '奖励发放',
  },
  activitycenter: {
    title: '活动中心',
    description: '完成任务，领取奖励，更多福利等你来领',
    action: '参与活动',
    accumulatedRewards: '累积奖励：',
    viewActivities: '查看活动',
    ongoingActivities: '进行中活动',
    completedActivities: '已完成活动',
  },
  Referral: {
    Refer: '推荐朋友赚取积分',
    description: `邀请朋友使用您的专属链接注册。一旦他们观看一个视频并在Aicean上首次分享，您的邀请将被确认。赶快邀请您的朋友体验Aicean，观看视频并赚取积分！`,
    referralLink: '推荐链接：',
    earningMethod: '如何轻松赚取积分',
    step1: '01. 获取链接',
    step1Details: `在Aicean注册并登录，然后与您尚未注册的朋友分享您的专属邀请链接。`,
    step2: '02. 推荐朋友',
    step2Details: `邀请您的朋友注册。他们需要观看一个视频并分享一次。`,
    step3: '03. 赚取积分',
    step3Details: `一旦成功，您将获得500积分。`,
    rewardDetails: '奖励详情',
    totalFriendsInvited: '邀请朋友总数',
    rewardPoints: '奖励积分',
    referralHistory: '推荐历史',
    date: '日期',
    address: '地址',
    status: '状态',
    reward: '奖励',
    noMoreData: '没有更多数据可用',
  },
  Leaderboard: {
    Weekly_RACE: '周赛',
    Leaderboard_Contest: '排行榜竞赛',
    Race_Rests: '距离比赛结束',
    DAY: '天',
    HOUR: '小时',
    MIN: '分钟',
    SEC: '秒',
    Has_ended: '已结束',
    Daily_Point: '本次比赛获得积分',
    Reward: '奖励',
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    August: '八月',
    September: '九月',
    October: '十月',
    November: '十一月',
    December: '十二月',
    tips1: '参与步骤：',
    tips2:
      '注册成为会员：报名参加活动成为参与者。绑定您的钱包地址：将您的钱包地址与活动关联，以确保您的',
    tips3: '活动规则：',
    tips4: '前1-3名：',
    tips5: '观看至少100个视频',
    tips6: '上传15个与AI相关的视频',
    tips7:
      '根据每日任务列表获得积分。最终排名前三的获胜者将根据最终积分排名选出。',
    tips8: '奖励分配：',
    tips9:
      '用户必须确保已绑定钱包地址才能接收奖励。最终排名将在活动结束时冻结，活动结束后的任何积分变动将不计入排名。',
    Learn_more: '了解更多',
    title: '排行榜',
    description:
      '排行榜仅根据积分进行排名，并不代表奖品分配。要获得奖励资格，参与者必须在活动中完成指定的任务。获奖者必须根据Twitter上的公告兑换奖品。如需了解完整规则，请访问：',
    campaignEndDate: '活动结束倒计时',
    status: '已结束',
    reward: '奖励金额',
    ranking: '排名',
    participantName: '用户名',
    contributionScore: '本次比赛获得积分',
    referredBy: '推荐人',
    uploadvideo: '上传视频',
    videoWatched: '观看视频',
    pop1: "1. 注册/登录",
      pop2: "2. 绑定您的钱包地址：将您的钱包地址与账户关联，以确保您的收益得到追踪。",
      pop3: "3. 完成日常任务：参与日常活动以赚取TAICE（积分）奖励。",
      pop4: "4. 尽可能多地赚取TAICE。您的贡献越多，奖励就越大。",
      pop5: "5. 查看您的结果：访问排行榜页面以查看您的排名和进度。",
      pop6: "前1-3名要求：",
      pop7: "邀请至少50位朋友",
      pop8: "观看至少100个视频",
      pop9: "上传15个与AI相关的视频",
      pop10: "从日常任务列表中赚取TAICE（积分）。前三名获奖者将根据最终积分排名确定。",
      pop11: "前4-103名要求：",
      pop12: "邀请至少20位朋友",
      pop13: "观看至少80个视频",
      pop14: "上传15个视频",
      pop15: "从日常任务列表中赚取TAICE（积分）。获奖者将根据最终积分排名确定。",
      pop16: "奖励分发：奖励将在验证后一周内分发。",
      pop17: "用户必须确保UID绑定了有效的钱包地址，以便接收奖励。",
      pop18: "最终排名将在活动结束时确定，之后发生的任何积分变动将不予考虑。",
      pop19: "花费积分不会影响排名。",
      pop20: "AICEAN保留最终决定权。",
      pop21: "如有任何疑问，请加入Telegram群组：http://t.me/Aicean 以联系我们的管理员。"
  },
  Task: {
    Signed_In: '连续签到',
    Sign_In: '立即签到',
    SignedIn: '已签到',
    tips1: 'AICEAN任务规则',
    tips2: '1. 任务设置',
    tips3: '每天5组任务，每组包含3个子任务。',
    tips4: '2. 奖励',
    tips5: '完成奖励：每组任务完成后获得积分。',
    tips6: '额外奖励：每组任务中所有3个子任务都完成后，额外获得100积分。',
    tips7: '3. 限制',
    tips8: '每个用户ID每天最多可完成5组任务。',
    tips9: '4. 重置',
    tips10: '倒计时结束后，任务和积分会自动重置。',
    Remain: '剩余：',
    Completed: '已完成',
    To_complete: '待完成',
    welcomeMessage: '欢迎来到',
    taskCenter: '任务中心',
    dailyTaskPrompt: '完成每日任务以获得更多积分',
    totalPointLabel: '总积分',
    totalViewingTimeLabel: '总观看时间',
    todayPointLabel: '今日积分',
    todayViewingTimeLabel: '今日观看时间',
    myTaskLabel: '我的任务',
    detailsButton: '详情',
    toFinishLabel: '待完成',
    dailyTaskCategory: '每日任务',
    newUserTaskCategory: '新用户任务',
    hotTaskCategory: '热门任务',
    challengeTaskCategory: '挑战任务',
  },
  login: {
    placeholder: '请选择登录方式',
    emailOption: '使用邮箱登录/注册',
    loginButton: '登录',
    signupWithEmail: '使用邮箱注册',
    forgetPassword: '忘记密码？',
    connectWallet: '连接钱包',
    agreeTo: '我同意',
    termsAndConditions: '条款与条件',
    signupButton: '注册',
    welcomeMessage: '欢迎来到AICEAN',
    walletAgreement: '通过连接您的钱包并使用AICEAN，您同意我们的条款与条件',
    cancelButton: '取消',
    acceptButton: '接受',
    resetButton: '重置',
    resetpassword: '重置密码',
    LoginEmail: '邮箱登录',
    LoginSuccess: '登录成功',
    emailMessage: '请输入正确的电子邮件地址',
    policyAgreement: '请同意政策',
    registrationSuccess: '注册成功',
    passwordResetSuccess: '密码重置成功',
  },
  header: {
    Beta: '测试版',
    Login: '登录',
    Home: '首页',
    Discover: '发现',
    Task: '任务',
    Leaderboard: '排行榜',
    Referral: '推荐',
    Campaign: '活动',
    Profile: '个人资料',
    Videos: '我的视频',
    Collections: '我的收藏',
    Performance: '作品分析',
    Setting: '设置',
    Logout: '退出登录',
    Points: '积分',
    Record: '记录',
    Today: '今日',
    Bind_account: '绑定账号',
    Invite: '邀请好友',
    Copy: '复制链接',
    Log_Out: '登出',
    Log_In: '登录',
    Sign_up: '注册',
    Welcome: '欢迎',
    Video: '视频',
    Fans: '粉丝',
    Follow: '关注',
  },
  Home: {
    WATCH: '观看',
    REWARDS: '奖励',
    SHARE: '分享',
    Make: '赚取',
    Money: '金钱',
    by: '通过',
    watching: '观看和分享视频',
    Complete: '完成任务并获得奖励',
    WATCH_AND: '观看并赚取',
    Sign: '注册并开始赚取',
    Leaderboard: '排行榜',
    Countdown: '倒计时',
    The_weekly: '如果您完成了最低竞赛要求，您将在排行榜上按您往竞赛期间获得的(TAICE)积分排名。',
    View_More: '查看更多',
    RANKING: '排名',
    NEW_EARNINGS: '新收益',
    Recommended_Videos: '推荐视频',
    TOP_PICKS: '精选内容',
    View_AII: '查看所有',
    Bonus_Status: '每日签到奖励',
    Day: '日',
    Partner: '合作伙伴',
    You_need: '您需要观看视频以完成签到',
    OK: '确定',
    Registration: '注册量',
    video_num: '视频数量',
    total_watch_video_duration: '总观看时长',
    Partner: '合作伙伴',
    Recommend: '推荐',
    More: '更多',
  },
  footer: {
    Policy: '隐私政策',
    Service: '服务条款',
    Copyright: 'Copyright 2024 AICEAN. All Right Reserved.',
  },
  sidebar: {
    Home: '首页',
    Tasks: '任务',
    Daily_task: '日常任务',
    Leaderboard: '排行榜',
    Videos: '视频',
    My_Portfolio: '我的',
    Points_Details: '积分详情',
    Watch_History: '观看历史',
    My_Collection: '我的收藏',
    My_Videos: '我的视频',
    My_Invitation: '我的邀请',
    History: '历史记录',
    Liked_Videos: '点赞视频',
    Subscriptions: '订阅',
    Copyright: 'Copyright 2024 AICEAN. All Right Reserved.',
    You: '您',
    About_Us: '关于我们',
    Regarding_Aicean: '关于Aicean',
    User_Agreement: '用户协议',
    Privacy_Agreement: '隐私协议',
    Contact_Us: '联系我们',
  },
  router: {
    VideoDetail: '视频详情',
    history: '历史记录',
    collection: '收藏',
    myvideos: '我的视频',
    invitation: '邀请',
    points: '积分',
    ProfileEdit: '编辑资料',
    Discover: '发现',
    leaderboard: '排行榜',
    changelanguage:'语言',
    Attention: '订阅',
},
  common: {
    Policy: '隐私政策',
    test: 'aasdasd',
    back: '返回',
    lang: 'English',
    nomore: '没有更多',
    Signsuccessful:'签到成功',
    copy: '复制成功！',
    Follow: '关注成功',
    Unfollow: '取消关注成功',
    Like: '喜欢成功',
    CancelLike: '取消喜欢成功',
    change: '更改成功!',
    say: '请说点什么！',
    comment: '评论成功!',
    operation: '操作成功!',
    incentiveclose: '活动已结束',
    claim: '操作成功',
    Cancel: '取消成功!',
    Collect: '收藏成功!',
    deletecomment: '确认删除此评论吗？',
    deletecommentsuccess: '删除评论成功！',
    share: '分享成功！',
    Campaign: '活动即将到来',
    delete: '删除成功!',
    Days: '天',
    Day: '天',
  },
}
