<template>
  <div class="bg flex flex-jc flex-ac" :style="[bg]">
    <div class="guid">
      <div class="title">
        {{ $t('guid.New_user') }}
        <!-- <img @click="colse" class="close" src="@/assets/v2/close.png" alt="" /> -->
      </div>
      <div class="box1" v-if="next == 1">
        <img class="img1" :src="wdWidth < 900
    ? require('@/assets/v2/guid1H5.png')
    : require('@/assets/v2/guid1.png')
    " alt="" />
        <div class="text1">
          {{ $t('guid.Welcome_to') }}
        </div>
        <div class="flex flex-jsb flex-ac box1_col">
          <div class="text2">（1/3）</div>
          <div class="btn" @click="tonext(1)">{{ $t('guid.Next') }}</div>
        </div>
      </div>
      <div class="box1" v-if="next == 2">
        <!-- <img class="img1" src="@/assets/v2/guid1.png" alt="" /> -->
        <iframe class="img1" id="ytplayer" type="text/html"
          :src="`https://www.youtube.com/embed/vFuHWOLTmhE?autoplay=1&origin=http://example.com`"
          frameborder="0"></iframe>
        <div class="text1">
          {{ $t('guid.New_users') }}
        </div>
        <div class="flex flex-jsb flex-ac box1_col">
          <div class="text2">（2/3）</div>
          <div class="btn" @click="tonext(2)" :class="time > 0 ? 'btn_none' : ''">
            {{ $t('guid.Next') }}
          </div>
        </div>
      </div>
      <div class="box1" v-if="next == 3">
        <img class="img1" src="@/assets/v2/guid2.png" alt="" />
        <div class="text1">
          {{ $t('guid.Congratulations') }}
        </div>
        <div class="flex flex-jsb flex-ac box1_col">
          <div class="text2">（3/3）</div>
          <div class="btn" @click="colse">{{ $t('guid.Sure') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
export default {
  name: 'newuserguid',
  props: {},
  data() {
    return {
      next: 1,
      time: 30,
    }
  },
  mounted() { },
  methods: {
    tonext(num) {
      if (num == 1) {
        this.next++
        this.timecolse()
      }
      if (num == 2 && this.time <= 0) {
        this.next++
      }
    },
    timecolse() {
      let interval = setInterval(() => {
        this.time--
        if (this.time <= 0) {
          clearInterval(interval)
        }
      }, 1000)
    },
    async colse() {
      await this.$request({
        path: 'user/completeGuide',
      })
      nextTick(() => {
        this.$store.commit('user/SET_SHOWNEWUSER', false)
        this.next = 1
        this.time = 30
      })
    },
  },
  computed: {
    ...mapGetters(['SPK', 'uid', 'shownewuserguid', 'wdWidth', 'signinfo']),
    bg() {
      return {
        backdropFilter: `blur(${this.shownewuserguid ? 7 : 0}px)`,
        pointerEvents: this.shownewuserguid ? 'auto' : 'none',
        opacity: this.shownewuserguid ? 1 : 0,
        zIndex: this.shownewuserguid ? 990 : -100,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.guid {
  width: 55.83rem;
  // height: 45.67rem;
  background: #1f2135;
  border-radius: 1.5rem;
  backdrop-filter: blur(10px);
}

.title {
  width: 100%;
  height: 5.83rem;
  background: #ffbe00;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  font-family: SVN-GilroySemiBold;
  font-size: 2rem;
  color: #161827;
  box-sizing: border-box;
  line-height: 2.5rem;
  padding: 0 2.92rem;
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  margin-left: auto;
  width: 2.5rem;
  height: 2.5rem;
}

.box1 {
  padding: 3rem;
}

.img1 {
  width: 100%;
  height: 25.33rem;
}

.text1 {
  text-align: left;
  margin-top: 2rem;
  font-size: 1.67rem;
  color: #ffffff;
  font-family: SVN-GilroySemiBold;
  line-height: 2.08rem;
  margin-bottom: 2rem;
}

.text2 {
  color: #999999;
  font-size: 1.5rem;
  font-family: SVN-Gilroy;
}

.btn {
  cursor: pointer;
  width: 10.83rem;
  height: 4rem;
  background: #edbf4f;
  border-radius: 2rem;
  font-family: SVN-GilroyMedium;
  font-size: 1.5rem;
  color: #161827;
  line-height: 4rem;
  text-align: center;

  &_none {
    background: #313342;
    color: #999999;
  }
}

.bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  opacity: 0;
  transition: all 0.35s;
}

@media screen and (max-width: 900px) {
  .guid {
    width: 26.25rem;
    // height: 45.67rem;
    background: #1f2135;
    border-radius: 1.5rem;
    backdrop-filter: blur(10px);
  }

  .title {
    width: 100%;
    height: 3.83rem;
    background: #ffbe00;
    border-radius: 1rem 1rem 0rem 0rem;
    font-family: SVN-GilroySemiBold;
    font-size: 1.33rem;
    color: #161827;
    box-sizing: border-box;
    line-height: 1.67rem;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
  }

  .close {
    cursor: pointer;
    margin-left: auto;
    width: 2.5rem;
    height: 2.5rem;
  }

  .box1 {
    padding: 0rem;
    &_col{
      flex-direction: column;
    }
  }

  .img1 {
    width: 100%;
    height: 12.33rem;
  }

  .text1 {
    text-align: left;
    margin-top: 1.42rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    font-size: 1.17rem;
    color: #ffffff;
    font-family: SVN-GilroySemiBold;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }

  .text2 {
    display: none;
    color: #999999;
    font-size: 1.5rem;
    font-family: SVN-Gilroy;
  }

  .btn {
    flex: 1;
    cursor: pointer;
    width: 23.75rem;
    height: 3rem;    
    background: #edbf4f;
    border-radius: 2rem;
    font-family: SVN-GilroyMedium;
    font-size: 1.5rem;
    color: #161827;
    line-height: 3rem;
    text-align: center;
    margin-bottom: 1rem;
    &_none {
      background: #313342;
      color: #999999;
    }
  }

  .bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    opacity: 0;
    transition: all 0.35s;
  }
}
</style>
