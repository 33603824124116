export default {
    guid: {
        New_user: '新用戶指引',
        Welcome_to: `歡迎來到AIcean官方網站。點擊任務中心查看您實時完成的任務`,
        New_users: `新用戶首次觀看視頻將獲得200 TAICE，立即觀看`,
        Congratulations: `恭喜，您已完成第一個任務並獲得200 TAICE。請繼續挑戰更多任務`,
        Next: `下一個`,
        Sure: `確定`,
    },
    my: {
        mine:'我的',
        notyet:'未上榜',
        Edit: '編輯',
        My_TAICE: '我的TAICE',
        Earned_Rewards: '我的獎勵',
        Invite_Friends: '邀請朋友',
        Invitation: '邀請碼',
        Referral: '推薦',
        Facebook: 'Facebook',
        Twitter: 'Twitter',
        WhatsApp: 'WhatsApp',
        Email: '電子郵件',
        Copy: '復製',
        Exclusive_Benefits: '專享福利',
        The_activities: '我參加的活動',
        Ranking_Activity: '活動排行榜',
        Your_ranking: '當前賽事排名',
        This_month: `本月賽事獲得的積分`,
        Expected: '預期獲得',
        Reason: '賽事最低要求',
        Invite: '邀請',
        Watch: '觀看',
        Upload: '上傳',
        Only: '僅差一名即可超越前一名',
        Fraction: '分段',
        Potential: '潛在排名',
        If_the: '如達成要求，將進行排名',
        Rankings: '排名',
        My_Videos: '我的視頻',
        View_All: '查看全部',
        No_data: '當前無數據',
        Watch_History: '觀看歷史',
        My_Collection: '我的收藏',
        My_Invitation: '我的邀請碼',
        Time: '時間',
        Address: '地址',
        State: '州/省',
        Reward: '獎勵',
        Points_Details: 'TAICE（積分）詳情',
        Details: '詳情',
        Obtain: '總積分',
        History: '歷史',
        A_total: '總計',
        items: '項目',
        Today: '今天',
        Yesterday: '昨天',
        Total_Invited: '總共邀請的朋友數',
        Get_Rewards: '領取獎勵',
        Invitation_record: '邀請記錄',
        User: '用戶',
        Option: '選項',
    },
    videos: {
        Latest_Content: '最新焦點內容',
        View_More: '查看更多',
        Currently: '熱門視頻',
        Recommended: '推薦頻道',
        Collected: '已收藏',
        Collect: '收藏',
        Creator: '查看創作者',
        Share: '分享',
        Views: '觀看次數',
    },
    Success: {
        message: '感謝您註冊！',
        description: `您現在正將時間轉化為收益。開始觀看視頻，發現令人興奮的機會，並讓您的獎勵不斷增長。讓我們讓每一分鐘都有價值！`,
        action: '現在開始賺錢！',
    },
    Started: {
        tagline: '透過分享您最愛的在線視頻來賺錢！',
        accountCreationPrompt: '準備好觀看了嗎？輸入您的電子郵件以創建帳戶',
        emailLabel: '電子郵件地址',
        registerButton: '立即註冊',
        earningStepsHeader: '4個步驟開始您的收益！',
        noSpendingRequired: '從不需要花費！',
        step1: '第1步. ',
        signUp: '註冊',
        step2: '第2步. ',
        watchVideos: '觀看視頻',
        step3: '第3步.',
        shareVideos: '分享視頻',
        step4: '第4步. ',
        inviteFriends: '邀請朋友',
        rankingHeader: '前4名至前103名：',
        winnersShare: '獲勝者將分享',
        rankingDisplay: '根據排行榜排名顯示',
        perUser: '每位用戶。',
        businessModel: '與用戶分享廣告獎勵的革命性商業模式！',
        earningPointsQuestion: '我如何賺取積分？',
        earningPointsAnswer: '觀看視頻後，系統將自動將積分添加到您的帳戶。',
        redeemPointsQuestion: '我能用積分兌換什麼？',
        redeemPointsAnswer: '目前，積分可以兌換TSPK代幣，未來將提供更多禮品選項。',
        registrationFeeQuestion: '註冊有費用嗎？',
        registrationFeeAnswer: '註冊和觀看視頻都是免費的，不需要任何費用。',
        videoTimeLimitQuestion: '觀看視頻有時間限制嗎？',
        videoTimeLimitAnswer: '沒有時間限制；您可以隨時在電腦、手機或iPad上觀看視頻。',
        checkPointsQuestion: '我如何查看我的積分？',
        checkPointsAnswer: '您可以在個人儀表板上查看當前的積分和代幣。',
        issueSupportQuestion: '如果遇到問題該怎麼辦？',
        issueSupportAnswer: '您可以通過我們的TG社群或X聯繫我們，我們將盡快協助您。',
        emailRequired: '請填寫電子郵件',
        validEmail: '請輸入正確的電子郵件地址',
    },
    Youtube: {
        selectAll: '全選',
        channelIdPlaceholder: '輸入YouTube頻道ID',
        searchButton: '搜尋',
        moreOptions: '更多',
        category: '類別',
        publishImmediately: '發佈（提交後立即發佈）',
        cancelButton: '取消',
        submitButton: '提交',
        message: '同步成功！',
        error: '請至少選擇一個視頻',
        categoryError: '請選擇一個類別',
        publishError: '請選擇發佈',
    },
    ProfileEdit: {
        avatarLabel: '頭像',
        saveButton: '儲存',
        nicknameLabel: '暱稱:',
        nicknamePlaceholder: '填寫暱稱',
        walletLabel: '錢包',
        connectWalletButton: '連接錢包',
        walletAddressLabel: '您的錢包地址',
        googleEmailBindingLabel: 'Google郵箱綁定',
        bindingButton: '綁定',
        googleEmailLabel: '您的Google郵箱',
        emailLabel: '電子郵箱',
        emailPlaceholder: '填寫電子郵箱',
        sendCodeButton: '發送驗證碼',
        verificationCodeLabel: '驗證碼',
        passwordLabel: '密碼',
        socialLinksLabel: '社交連結',
        message: '獲取用戶信息失敗',
        savesuccess: '保存成功',
        status: '發送驗證碼成功',
        codeStatus: '驗證碼不為空',
        walletBindingPrompt: '請綁定錢包',
        passwordError: '密碼不能為空',
        walletConnectionPrompt: '請連接錢包',
        requestError: '請求失敗',
        emailChangeStatus: '更改電子郵箱成功',
        walletAlreadyBound: '用戶已綁定錢包',
        avatar: '頭像不為空',
        nickname: '暱稱不為空',
        nicknamelonger: '暱稱過長',
        twitter: 'Twitter不為空',
        facebook: 'Facebook不為空',
        youtube: 'YouTube不為空',
        instagram: 'Instagram不為空',
        emailValidator: '電子郵箱不為空',
    },
    Analyze: {
        viewType: '瀏覽',
        interactionType: '點讚',
        commentType: '評論',
        followerCount: '關注者',
        watchTimeHours: '觀看時間（小時）',
        totalWatchTime: '視頻發佈以來的總觀看時間。',
        avgViewDuration: '平均觀看時長',
        avgWatchTime: '觀眾觀看此視頻的平均時長。',
    },
    Collect: {
        collections: '我的收藏',
    },
    selfProfileCreation: {
        profileName: '我的影片',
        action: '創建',
        platform: 'YouTube',
        filter: '全部',
        sortOrder: '最新',
        sortByViews: '最多觀看',
        sortByLikes: '最多點讚',
        collections: '我的收藏',
    },
    component_user: {
        totalPoints: '總積分：',
        totalViewingTime: '總觀看時間：',
        videos: '影片',
        followers: '追隨者',
        following: '關注中',
    },
    selfprofile_my: {
        History: '歷史記錄',
    },
    ExchangeHistory: {
        word: '說明',
        points: '貢獻積分',
        timestamp: '時間',
        details: '詳情',
    },
    videoDetail: {
        actionWatch: '觀看',
        actionEarn: '賺取',
        actionView: '瀏覽',
        followersCount: '關注數',
        actionShare: '分享',
        noTaskMessage: '當前無任務',
        actionClaim: '領取',
        commentsCount: '評論數',
        loginAction: '登入',
        actionRelease: '發佈',
        replyAction: '回覆',
        totalReplies: '總回覆數',
        loadMoreAction: '加載更多',
        noMoreCommentsMessage: '沒有更多評論',
        incentiveMessage: '激勵',
        recommendedLabel: '推薦',
        watchTodayAction: '今日觀看',
        Follow: '關注',
        Unfollow: '取消關注',
        Copy: '複製',
        Like: '點讚',
        Collect: '收藏',
        AddComments: '添加評論',
        Search: '搜尋',
    },
    activityinfo: {
        signupMessage: '立即註冊開始賺錢！',
        eventEndTime: '活動於結束',
        rankingInfo: '前4名至前103名：根據排行榜顯示。',
        participationSteps: '參與步驟',
        activityRules: '活動規則',
        rewardDistribution: '獎勵分佈',
    },
    activitycenter: {
        title: '活動中心',
        description:
            '完成任務，領取獎勵，還有更多福利等您來收集',
        action: '活動',
        accumulatedRewards: '累積獎勵：',
        viewActivities: '查看活動',
        ongoingActivities: '進行中活動',
        completedActivities: '已完成活動',
    },
    Referral: {
        Refer: '推薦朋友賺積分',
        description: `使用您的專屬鏈接邀請朋友註冊。他們在Aicean上首次觀看一個視頻並分享後，您的邀請將被確認。趕快邀請您的朋友體驗Aicean，觀看視頻並賺取積分！`,
        referralLink: '推薦鏈接：',
        earningMethod: '如何輕鬆賺取積分',
        step1: '01. 獲取鏈接',
        step1Details: `註冊並登錄Aicean，然後將您的專屬邀請鏈接分享給尚未註冊的朋友。`,
        step2: '02. 推薦朋友',
        step2Details: `邀請您的朋友註冊。他們需要觀看一個視頻並分享一次。`,
        step3: '03. 賺取積分',
        step3Details: `成功后，您將獲得500積分。`,
        rewardDetails: '獎勵詳情',
        totalFriendsInvited: '總共邀請的朋友數',
        rewardPoints: '獎勵積分',
        referralHistory: '推薦歷史',
        date: '日期',
        address: '地址', 
        status: '狀態',
        reward: '獎勵',
        noMoreData: '沒有更多數據可用',
    },
    Leaderboard: {
        Weekly_RACE: '每週競賽',
        Leaderboard_Contest: '排行榜競賽',
        Race_Rests: '競賽剩餘時間',
        DAY: '天',
        HOUR: '小時',
        MIN: '分鐘',
        SEC: '秒',
        Has_ended: '上一場競賽（已結束）',
        Daily_Point: '本場競賽獲得的积分',
        Reward: '獎勵',
        January: '一月',
        February: '二月',
        March: '三月',
        April: '四月',
        May: '五月',
        June: '六月',
        July: '七月',
        August: '八月',
        September: '九月',
        October: '十月',
        November: '十一月',
        December: '十二月',
        tips1: '如何參與：',
        tips2: `1. 註冊/登錄 2. 綁定錢包地址：鏈接您的錢包地址以確保您的收益被追蹤。`,
        tips3: '排行榜規則：',
        tips4: '前1名至前3名的要求：',
        tips5: '邀請至少50位朋友',
        tips6: '觀看至少100個視頻',
        tips7: `上傳15個與AI相關的視頻`,
        tips8: '獎勵分佈：',
        tips9: `獎勵分佈：獎勵將在驗證後一周內分發。用戶必須確保UID綁定了一個有效的錢包地址才能接收獎勵。最終排名將在活動結束時確定，之後發生的任何積分變動都不會被考慮。`,
        Learn_more: '了解更多',
        title: '排行榜',
        description:
            '排行榜僅根據積分進行排名，並不代表獎勵的分佈。要符合獎勵資格，參與者必須在活動期間內完成指定的任務。獲獎者必須根據Twitter上的公告領取獎品。有關完整規則，請參閱：',
        campaignEndDate: '活動結束時間',
        status: '已完成',
        reward: '獎勵金額',
        ranking: '排名',
        participantName: '用戶名',
        contributionScore: '本場競賽獲得的积分',
        referredBy: '推薦用戶',
        uploadvideo: '上傳視頻',
        videoWatched: '觀看視頻',
        pop1: `1. 註冊 / 登入`,
    pop2: `2. 綁定您的錢包地址：將您的錢包地址與我們系統連結，確保您的收益能被追蹤。`,
    pop3: `3. 完成每日任務：參與日常活動以贏取TAICE（積分）獎勵。`,
    pop4: `4. 盡可能多地贏取TAICE。您的貢獻越多，獎勵就越豐厚。`,
    pop5: `5. 查看您的結果：訪問排行榜頁面以檢查您的排名和進度。`,
    pop6: `前1至前3名要求：`,
    pop7: `邀請至少50位朋友`,
    pop8: `觀看至少100個視頻`,
    pop9: `上傳15個與AI相關的視頻`,
    pop10: `從日常任務列表中贏取TAICE（積分）。前三名獲勝者將根據最終積分排名確定。`,
    pop11: `前4至前103名要求：`,
    pop12: `邀請至少20位朋友`,
    pop13: `觀看至少80個視頻`,
    pop14: `上傳15個視頻`,
    pop15: `從日常任務列表中贏取TAICE（積分）。獲勝者將根據最終積分排名確定。`,
    pop16: `獎勵分發：獎勵將在驗證後一周內發放。`,
    pop17: `用戶必須確保有一個與UID綁定的有效錢包地址才能接收獎勵。`,
    pop18: `最終排名將在活動結束時確定，之後的任何積分變動將不予考慮。`,
    pop19: `花費積分不會影響排名。`,
    pop20: `AICEAN保留最終決定權。`,
    pop21: `如有任何疑問，請加入Telegram群組：http://t.me/Aicean 以聯繫我們的管理員。`
    },
    Task: {
        Signed_In: '連續每日簽到',
        Sign_In: '立即簽到',
        SignedIn: '已簽到',
        tips1: 'AICEAN 任務規則',
        tips2: '1. 任務設置',
        tips3: '每日5組任務，每組包含3個子任務。',
        tips4: '2. 獎勵',
        tips5: '完成獎勵：每完成一組任務即可獲得積分。',
        tips6: '額外獎勵：一組內完成所有3個子任務可額外獲得100積分。',
        tips7: '3. 限制',
        tips8: '每個UID每日最多可完成5組任務。',
        tips9: '4. 重置',
        tips10: `任務將於每日UTC+8 00:00重置。
  從日常任務獲得的TAICE（積分）不會被扣除。
  `,
        Remain: '倒計時：',
        Completed: '已完成',
        To_complete: '待完成',
        welcomeMessage: '歡迎來到',
        taskCenter: '任務中心',
        dailyTaskPrompt: '完成日常任務以獲得更多積分',
        totalPointLabel: '總積分',
        totalViewingTimeLabel: '總觀看時間',
        todayPointLabel: '今日積分',
        todayViewingTimeLabel: '今日觀看時間',
        myTaskLabel: '我的任務',
        detailsButton: '詳情',
        toFinishLabel: '待完成',
        dailyTaskCategory: '日常任務',
        newUserTaskCategory: '新用戶任務',
        hotTaskCategory: '熱門任務',
        challengeTaskCategory: '挑戰任務',
    },
    login: {
        placeholder: '請選擇登錄方式',
        emailOption: '使用電子郵件登錄/註冊',
        loginButton: '登錄',
        signupWithEmail: '使用電子郵件註冊',
        forgetPassword: '忘記密碼？',
        connectWallet: '連接錢包',
        agreeTo: '我同意',
        termsAndConditions: '條款與條件',
        signupButton: '註冊',
        welcomeMessage: '歡迎來到AICEAN',
        walletAgreement:
            '通過連接您的錢包並使用AICEAN，您即同意我們的條款與條件',
        cancelButton: '取消',
        acceptButton: '接受',
        resetButton: '重置',
        resetpassword: '重置密碼',
        LoginEmail: '使用電子郵件登錄',
        LoginSuccess: '登錄成功',
        emailMessage: '請輸入正確的電子郵件地址',
        policyAgreement: '請同意政策',
        registrationSuccess: '註冊成功',
        passwordResetSuccess: '密碼重置成功',
    },
    header: {
        Beta: 'Beta版',
        Login: '登入',
        Home: '首頁',
        Discover: '發現',
        Task: '任務',
        Leaderboard: '排行榜',
        Referral: '推薦',
        Campaign: '活動',
        Profile: '個人資料',
        Videos: '我的影片',
        Collections: '我的收藏',
        Performance: '表現',
        Setting: '設置',
        Logout: '登出',
        Points: '積分',
        Record: '記錄',
        Today: '今日',
        Bind_account: '綁定帳號',
        Invite: '邀請朋友',
        Copy: '複製鏈接',
        Log_Out: '登出', 
        Log_In: '登入', 
        Sign_up: '註冊',
        Welcome: '歡迎',
        Video: '影片',
        Fans: '粉絲',
        Follow: '關注',
    },
    Home: {
        WATCH: '觀看',
        REWARDS: '獎勵',
        SHARE: '分享',
        Make: '賺取',
        Money: '金錢',
        by: '由',
        watching: '通過觀看和分享影片',
        Complete: '完成任務並獲得獎勵',
        WATCH_AND: '觀看並賺取',
        Sign: '立即註冊並開始賺錢',
        Leaderboard: '排行榜',
        Countdown: '倒計時',
        The_weekly: `若您已完成最低賽事要求，則將根據賽事期間您獲得的總（TAICE）積分在排行榜上進行排名。`,
        View_More: '查看更多',
        RANKING: '排名',
        NEW_EARNINGS: '新收益',
        Recommended_Videos: '推薦影片',
        TOP_PICKS: '精選',
        View_AII: '查看所有', 
        Bonus_Status: '每日簽到獎勵',
        Day: '天',
        Partner: '合作夥伴',
        You_need: '您需要觀看一部影片以完成簽到流程',
        OK: '確定',
        Registration: '註冊量',
        video_num: '影片數量',
        total_watch_video_duration: '觀看影片總時長',
        Recommend: '推薦', 
        More: '更多',
    },
    footer: {
        Policy: '隱私政策',
        Service: '服務條款',
        Copyright: '版權所有 © 2024 AICEAN. 保留所有權利。',
    },
    sidebar: {
        Home: '首頁',
        Tasks: '任務',
        Daily_task: '每日任務',
        Leaderboard: '排行榜',
        Videos: '影片',
        My_Portfolio: '我的',
        Points_Details: 'TAICE（積分）詳情',
        Watch_History: '觀看歷史',
        My_Collection: '我的收藏', 
        My_Videos: '我的影片',
        My_Invitation: '我的邀請',
        History: '歷史記錄',
        Liked_Videos: '點讚的影片',
        Subscriptions: '訂閱',
        Copyright: '版權所有 © 2024 AICEAN. 所有權利保留。',
        You: '您',
        About_Us: '關於我們',
        Regarding_Aicean: '關於AICEAN',
        User_Agreement: '用戶協議', 
        Privacy_Agreement: '隱私政策', 
        Contact_Us: '聯繫我們',
    },
    router: {
        VideoDetail: '視頻詳情',
        history: '歷史記錄',
        collection: '收藏',
        myvideos: '我的視頻',
        invitation: '邀請',
        points: '積分',
        ProfileEdit: '編輯資料',
        Discover: '發現',
        leaderboard: '排行榜',
        changelanguage: '語言',
        Attention: '訂閲',
    },
    common: {
        Policy: '隱私政策',
        test: '12345adsas', 
        back: '返回',
        lang: '繁體中文', 
        nomore: '沒有更多',
        copy: '複製成功！',
        Signsuccessful:'签到成功',
        Follow: '關注成功',
        Unfollow: '取消關注成功',
        Like: '點讚成功',
        CancelLike: '取消點讚成功',
        change: '更改成功！',
        say: '請輸入一些內容！',
        comment: '評論成功！',
        operation: '操作成功！',
        incentiveclose: '此激勵活動已結束',
        claim: '領取成功！',
        Cancel: '取消成功！',
        Collect: '收藏成功！',
        deletecomment: '確認刪除此評論？',
        deletecommentsuccess: '刪除評論成功！',
        share: '分享成功！',
        Campaign: '即將到來的活動',
        delete: '刪除成功！',
        Days: '天',
        Day: '天',
    },
}
