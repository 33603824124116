import store from '@/store'

export function init() {
  store.commit('common/SET_SIZE', {
    height: window.innerHeight,
    width: window.innerWidth,
  })

  window.onresize = function () {
    store.commit('common/SET_SIZE', {
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }
}

export function _isMobile() {
  // console.log(navigator.userAgent)
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

const chars =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')

export function random64() {
  let str = ''
  for (let i = 0; i < 64; i++) {
    str += chars[Math.round(Math.random() * chars.length)]
  }
  return str
}

let th_flag

export function _throttle(fn, delay = 1000, immediate = true, ...args) {
  let timer = null
  if (!immediate) {
    if (!th_flag) {
      th_flag = true
      timer = setTimeout(() => {
        typeof fn === 'function' && fn.apply(this, args)
        th_flag = false
      }, delay)
    }
  } else {
    if (!th_flag) {
      th_flag = true
      console.log(Object.prototype.toString.call(fn))
      typeof fn === 'function' && fn.apply(this, args)
      timer = setTimeout(() => {
        th_flag = false
      }, delay)
    }
  }
  return timer
}

/**
 * 创建随机字符串
 * @param {String | Number } num
 * @returns {string}
 **/
export function randomString(num) {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  let res = ''
  for (let i = 0; i < num; i++) {
    res += chars[Math.ceil(Math.random() * 35)]
  }
  return res
}
export function checkDate(startTime, endTime, compDay) {
  if (startTime == '' || startTime == null || startTime == undefined) {
    console.log('开始时间为空，请检查！')
    return true
  }
  if (endTime == '' || endTime == null || endTime == undefined) {
    console.log('结束时间为空，请检查！')
    return true
  }
  var data1 = Date.parse(startTime.replace(/-/g, '/'))
  var data2 = Date.parse(endTime.replace(/-/g, '/'))

  var datadiff = data2 - data1
  var time = parseInt(compDay) * (60 * 60 * 24 * 1000)
  console.log(datadiff, time)
  if (datadiff < 0) {
    console.log('开始时间应小于结束时间')
    return false
  }
  if (datadiff > time) {
    console.log('时间间隔大于' + parseInt(compDay) + '天，请检查!')
    return true
  }
  return false
}
export function formatSeconds(seconds = 0, isshow) {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds % 3600) / 60)
  let secs = seconds % 60
  if (isshow) {
    hours = hours > 0 ? (hours < 10 ? `0${hours}h` : `${hours}h`) : ''
    minutes = minutes < 10 ? `${minutes}m` : `${minutes}m`
    secs = secs < 10 ? `0${secs}s` : `${secs}s`
  } else {
    hours = hours > 0 ? (hours < 10 ? `0${hours}:` : `${hours}:`) : ''
    minutes = minutes < 10 ? `${minutes}` : `${minutes}`
    secs = secs < 10 ? `0${secs}` : `${secs}`
  }

  // 返回字符串，注意这里按照你的特殊需求添加了'H', 'Min', 'S'
  // 但通常我们不会这样格式化，因为这不符合常规的时间表示习惯
  return `${hours}${minutes}:${secs}`
}
export function remToPx(remValue) {
  const baseFontSize = parseInt(
    getComputedStyle(document.documentElement).fontSize
  )
  return baseFontSize * remValue
}
