<template>
  <div class="content" :class="$route.name == 'about' ? 'content-bg' : ''">
    <div class="contentbox flex flex-grow">
      <Sidebar v-if="token && wdWidth >= 900"></Sidebar>
      <div class="main">
        <router-view />
      </div>
    </div>
    <!-- <slot v-if="wdWidth >= 900"></slot> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Sidebar from '@/components/sidebar/sidebar.vue'
export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters(['wdWidth', 'token']),
  },
}
</script>

<style lang="scss" scoped>
div {
  user-select: none;
  color: #fff;
}
.content-bg {
  background: url('@/assets/v2/loginbg.png') top left no-repeat !important;
}
.content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #161827;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.contentbox {
  margin: 0 auto;
}
.main {
  flex: 1;
  width: 97.92rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 900px) {
  .main {
    width: -webkit-fill-available;
  }
  .contentbox {
    width: 29.25rem;
    padding: 0 1rem;
  }
}
</style>
